import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { CellChange, Row } from "@silevis/reactgrid";

import CustomCard from "@/components/Card";
import InputField from "@/components/fields/InputField";

import dictionary from "@/constants/dictionary";

import useThemeStyling from "@/utils/useThemeStyling";
import { ForecastFmbState } from "@/models/autoFmb";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import SummaryTable from "@/components/SummaryTable";

import { SummaryFmb } from "../../context/AutoFmbContext";
import { summaryFmbTableHeader } from "../../constants";
import CustomTable from "@/components/CustomTable";
import { forecastEventTableColumn } from "../../hooks/useAutoFmbForecast";
import { useMemo, useState } from "react";
import IntervalButton from "@/components/IntervalButton";
import { Interval } from "@/models/Generic";
import { ModuleId } from "@/model";

type ForecastParameterProps = {
  isLoading: boolean;
  forecastFmbState?: ForecastFmbState;
  onChangeParameter: (key: string, value: any) => void;
  errorInputValidation: ErrorValidationDetail[];
  summaryTableData: SummaryFmb;
  parameterTableRow: Row<any>[];
  onTableCellChange: (changes: CellChange[]) => void;
  onClickCalculate: () => void;
  onClickExport: (option: Interval) => void;
  currentModule?: ModuleId;
};

const ForecastParameter = ({
  isLoading,
  forecastFmbState,
  onChangeParameter,
  summaryTableData,
  errorInputValidation,
  onTableCellChange,
  parameterTableRow,
  onClickCalculate,
  onClickExport,
  currentModule,
}: ForecastParameterProps) => {
  const { palette } = useThemeStyling();

  const [canExport, setCanExport] = useState(false);

  const forecastEventErrors = useMemo(() => {
    return errorInputValidation.filter((err) => err.loc.includes("forecast_events"));
  }, [errorInputValidation]);

  return (
    <CustomCard
      style={{
        height: "100%",
      }}
    >
      <h3 style={{ color: palette.primary.main, margin: 0 }}>{dictionary.fmb.forecastSettings}</h3>
      <FormControlLabel
        control={
          forecastFmbState ? (
            <Checkbox
              onChange={(e) => {
                onChangeParameter("smart_fill", e.target.checked);
              }}
              checked={forecastFmbState?.smart_fill}
              size="small"
              data-testid="auto-update-smart-fill-checkbox"
            />
          ) : (
            <></>
          )
        }
        label={dictionary.fmb.smartFill}
      />
      <InputField
        type="float"
        suffix={dictionary.fmb.economicCutoffgasNotation}
        disabled={isLoading || forecastFmbState?.smart_fill}
        value={forecastFmbState?.economic_cutoff}
        label={dictionary.fmb.economicCutoff}
        debounceDelay={10}
        onChange={(v) => {
          onChangeParameter("economic_cutoff", v);
        }}
        errors={errorInputValidation}
        keyField={`forecast.economic_cutoff`}
        dataTestId="economic_cutoff"
      />
      {forecastEventErrors.length > 0 && (
        <div style={{ color: palette.error.main }}>
          Forecast Events: <br />
          {forecastEventErrors.map((err) => err.msg)}
        </div>
      )}

      <div
        style={{
          minHeight: 200,
        }}
      >
        <CustomTable
          style={{
            height: 400,
          }}
          columns={forecastEventTableColumn}
          rows={parameterTableRow}
          enableRangeSelection
          enableColumnSelection
          stickyTopRows={2}
          onCellsChanged={onTableCellChange}
          isLoading={false}
        />
      </div>
      <Button
        style={{
          color: "white",
          width: "100%",
          fontWeight: "bold",
          marginBottom: 15,
          marginTop: 20,
        }}
        disabled={isLoading}
        variant="contained"
        onClick={() => {
          onClickCalculate();
          setCanExport(true);
        }}
      >
        {dictionary.genericButton.applyUpdates}
      </Button>
      <IntervalButton
        data-testid="export-forecast-fmb"
        label={dictionary.rulon.export}
        loading={isLoading}
        disabled={isLoading || !canExport || currentModule === ModuleId.MASLO_AUTOFMB}
        onClickButton={(option) => {
          onClickExport(option);
        }}
      />
      <h3 style={{ color: palette.primary.main, margin: 0, marginTop: 10 }}>{dictionary.fmb.summary}</h3>
      <SummaryTable rows={summaryTableData} headers={summaryFmbTableHeader} />
    </CustomCard>
  );
};

export default ForecastParameter;

import React from "react";

// utils
import useAutoRtaForecast, { AutoRtaForecastProps } from "../../hooks/rtaForecast/useAutoRtaForecast";

// component
import AutoRtaForecastSetting from "./AutoRtaForecastSetting";
import AutoRtaForecastCalculation from "./AutoRtaForecastCalculation";
import useAutoRtaForecastResult from "../../hooks/rtaForecast/useAutoRtaForecastResult";

const AutoRtaForecastContainer = (props: AutoRtaForecastProps) => {
  const {
    errorInputValidation,
    forecastInputState,
    onChangeForecastInput,
    onChangeForecastInputTable,
    forecastEventsRow,
    loadForecast,
    onClickCalculateForecast,
    autoRtaForecastParameter,
    fluidType,
    canCancelPoll,
    onCancelPoll,
    expandParameter,
    autoRtaForecastCalculation,
    autoRtaFieldData,
  } = useAutoRtaForecast();

  const {
    forecastCalculationDataTableCol,
    fieldDataRow,
    fieldDataCol,
    forecastCalculationDataTableRow,
    forecastXAxes,
    forecastYAxes,
    forecastCalcSeries,
    cumulativeGasChartSeries,
    cumulativeGasYAxes,
  } = useAutoRtaForecastResult({ autoRtaFieldData, autoRtaForecastCalculation, fluidType });

  const loadingState = loadForecast || props.isLoading;

  const canCalculate = !autoRtaForecastCalculation;

  // don't display anything if no forecastInputState
  if (!forecastInputState) return <></>;

  return (
    <div style={{ height: "93%", display: "grid", gridTemplateColumns: expandParameter ? "440px 1fr" : "1fr", gridGap: 20, padding: "1em" }}>
      <div
        style={{
          display: expandParameter ? "unset" : "none",
        }}
      >
        <AutoRtaForecastSetting
          onChangeInput={onChangeForecastInput}
          forecastInput={forecastInputState}
          onChangeTableInput={onChangeForecastInputTable}
          errorInputValidation={errorInputValidation}
          forecastEventsRow={forecastEventsRow}
          autoRtaForecastParameter={autoRtaForecastParameter}
          isLoading={loadingState}
          onClickValidate={onClickCalculateForecast}
          fluidType={fluidType}
          canCancelPoll={canCancelPoll}
          onCancelPoll={onCancelPoll}
          canCalculate={canCalculate}
          isFromInitialize={autoRtaForecastCalculation?.isFromInitialize}
        />
      </div>
      <AutoRtaForecastCalculation
        forecastCalculationDataTableCol={forecastCalculationDataTableCol}
        dataTableRow={forecastCalculationDataTableRow}
        fieldDataCol={fieldDataCol}
        fieldDataRow={fieldDataRow}
        forecastXAxes={forecastXAxes}
        forecastYAxes={forecastYAxes}
        isLoading={loadingState}
        forecastCalcSeries={forecastCalcSeries}
        cumulativeGasChartSeries={cumulativeGasChartSeries}
        cumulativeGasYAxes={cumulativeGasYAxes}
        fluidType={fluidType}
      />
    </div>
  );
};

export default AutoRtaForecastContainer;

import { shallow } from "zustand/shallow";

import { useAppStore } from "@/features/app";

import EconomicParameter from "./components/EconomicParameter";
import EconomicChart from "./components/EconomicChart";
import useEconomics from "./hooks/useEconomics";

type EconomicContainerProps = {
  // because economic is both stand alone but also depend on the other tab in 1 modules ( depend on the previous tab value)
  // need user to click re calculate button when there's a value change
  haveChangeValue: boolean;
  setHaveChangeValue: (val: boolean) => void;
  // when user is on the right tab
  canFetch: boolean;
};

const EconomicContainer = ({ haveChangeValue, setHaveChangeValue, canFetch }: EconomicContainerProps) => {
  const { selectedDataSets, isLoading, project, setApiError, apiError, expandParameter, setIsLoading, currentModule } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      project: state.project,
      setApiError: state.setApiError,
      apiError: state.apiError,
      expandParameter: state.expandParameter,
      setIsLoading: state.setIsLoading,
      currentModule: state.currentModule,
    }),
    shallow
  );

  const { loadingState, economicState, grossCapexRow, onChangeParameter, onChangeGrossCapexTable, errorInputValidation, onClickCalculateEconomic } =
    useEconomics({
      selectedDataSets,
      isLoading,
      project,
      setApiError,
      apiError,
      haveChangeValue,
      setHaveChangeValue,
      setIsLoading,
      canFetch,
      currentModule,
    });

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: expandParameter ? "480px 1fr" : "1fr",
        gridGap: 20,
        padding: "1em",
      }}
    >
      <div
        style={{
          display: expandParameter ? "unset" : "none",
        }}
      >
        <EconomicParameter
          parameter={economicState?.economic_option}
          onChangeParameter={onChangeParameter}
          isLoading={loadingState}
          validationError={errorInputValidation}
          grossCapexRow={grossCapexRow}
          onChangeGrossCapexTable={onChangeGrossCapexTable}
          onClickCalculateEconomic={onClickCalculateEconomic}
          canClickCalculate={!economicState?.economic_result}
        />
      </div>
      <EconomicChart economicResult={economicState?.economic_result} loadingState={loadingState} />
    </div>
  );
};

export default EconomicContainer;

import { useMemo } from "react";

import CustomCard from "@/components/Card";
import Tabs from "@/components/Tabs";
import FossilyticsChart from "@/components/FossilyticsChart";
import CustomTable from "@/components/CustomTable";
import { InputSummaryCard } from "@/features/shared";

import dictionary from "@/constants/dictionary";
import useEconomicChart, { EconomicChartProps } from "../hooks/useEconomicChart";

import { formatMoney } from "@/utils/general";

const EconomicChart = ({ economicResult, loadingState }: EconomicChartProps) => {
  const { chartAxes, economicDataTableCol, economicDataTableRow, forecastCalcSeries } = useEconomicChart({ economicResult, loadingState });

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.economics.cumulativeCashFlowChart}</span>,
        key: dictionary.economics.cumulativeCashFlowChart,
        content: (
          <div
            style={{
              height: 600,
            }}
          >
            <FossilyticsChart
              id={"economicChart"}
              key={"economicChart"}
              isLoading={loadingState}
              xAxes={chartAxes.xAxes}
              yAxes={chartAxes.yAxes}
              series={forecastCalcSeries}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.dataTable}</span>,
        key: "Data table",
        content: (
          <div>
            <CustomTable
              style={{
                display: "flex",
                maxHeight: 500,
              }}
              rows={economicDataTableRow}
              columns={economicDataTableCol}
              isLoading={loadingState}
              stickyTopRows={1}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.economics.economicsSummary}</span>,
        key: dictionary.economics.economicsSummary,
        content: (
          <div>
            <InputSummaryCard customFormat={(val) => formatMoney(Number(val))} isLoading={loadingState} summaryCard={economicResult?.summary_card} />
          </div>
        ),
      },
    ];
  }, [chartAxes.xAxes, chartAxes.yAxes, economicDataTableCol, economicDataTableRow, economicResult?.summary_card, forecastCalcSeries, loadingState]);

  return (
    <CustomCard
      style={{
        opacity: loadingState ? 0.5 : 1,
        position: "relative",
        display: "flex",
        height: "100%",
      }}
    >
      {!economicResult && <div className="overlay-section" />}

      <Tabs tabList={tabList} />
    </CustomCard>
  );
};

export default EconomicChart;

import { autoFmbInitializeApi, autoFmbInitializeApiV2 } from "@/constants/apiUrl";
import { fetchApi } from "@/utils/apiFetcher";

import { FluidType } from "@/models/Generic";

import { AutoFmbState } from "../state";

type InitializeFmbPayload = {
  data_set_ids: string[];
};

export const postInitializeFmb = (projectId: string, fluidModuleType: FluidType, body: InitializeFmbPayload) => {
  if (fluidModuleType === FluidType.oil) {
    return fetchApi<AutoFmbState, InitializeFmbPayload>({
      path: autoFmbInitializeApiV2(projectId),
      type: "post",
      body,
    });
  }
  return fetchApi<AutoFmbState, InitializeFmbPayload>({
    path: autoFmbInitializeApi(projectId),
    type: "post",
    body,
  });
};

import { object, number, string, z } from "zod";
import { SummaryCardScheme } from "../Generic";

const economicsOptionScheme = object({
  gas_shrinkage: number(),
  gas_higher_heating_value: number(),
  working_interest: number(),
  royalty_burden: number(),
  fixed_opex: number(),
  variable_gas_opex: number(),
  variable_oil_opex: number(),
  variable_water_opex: number(),
  gross_abandonment_capex: number(),
  severance_ad_valorem_tax_rate: number(),
  gas_price: number(),
  oil_price: number(),
  discount_rate: number(),
  gross_capex_schedule: object({
    dates: string().date().array(),
    capex: number().array(),
  }),
});

export type EconomicsOption = z.infer<typeof economicsOptionScheme>;

export const economicsResultScheme = object({
  dates: string().date().array(),
  gross_income: number().array(),
  net_severance_taxes: number().array(),
  net_income: number().array(),
  net_opex: number().array(),
  net_operating_income: number().array(),
  net_capex: number().array(),
  cash_flow_bfit: number().array(),
  discounted_cash_flow_bfit: number().array(),
  cumulative_discounted_cash_bfit: number().array(),
  summary_card: SummaryCardScheme.array(),
});

export type EconomicsResult = z.infer<typeof economicsResultScheme>;

export const economicStateScheme = object({
  economic_option: economicsOptionScheme,
  economic_result: economicsResultScheme.nullable(),
});

export type EconomicState = z.infer<typeof economicStateScheme>;

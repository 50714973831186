import DefinedParameters from "@/features/shared/DefinedParameters";
import InputSummaryCard from "@/features/shared/InputSummaryCard";

import { useAutoFmbState } from "../../context/AutoFmbContext";
import useAutoFmbInput from "../../hooks/useAutoFmbInput";
import ModelComponents from "./ModelComponent";
import { FluidType } from "@/models/Generic";

const AutoFmbInput = () => {
  const {
    isLoading,
    apiError,
    autoFmbState,
    setAutoFmbState,
    analysisIdentity,
    tabIndex,
    setApiError,
    fluidModuleType,
    setAutoFmbAnalysisCalculation,
    setAutoFmbForecastCalculation,
    updateInputFromAnalysis,
    setUpdateInputFromAnalysis,
    flowingPressureOptions,
  } = useAutoFmbState();

  const { updateInputFmb, errorInputValidation, autoFmbInputCalculation, loadingState } = useAutoFmbInput({
    setApiError,
    setAutoFmbState,
    tabIndex,
    analysisIdentity,
    apiError,
    autoFmbState,
    fluidModuleType,
    setAutoFmbAnalysisCalculation,
    setAutoFmbForecastCalculation,
    updateInputFromAnalysis,
    setUpdateInputFromAnalysis,
  });

  const totalLoadingState = loadingState || isLoading;

  return (
    <div
      style={{
        padding: "1em",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 20,
          marginBottom: 20,
        }}
      >
        <DefinedParameters
          isLoading={totalLoadingState}
          definedParameters={autoFmbState?.inputs?.defined_parameters}
          onChange={(val, key) => {
            updateInputFmb(val, key, "defined_parameters");
          }}
          validationError={errorInputValidation}
        />
        <InputSummaryCard isLoading={totalLoadingState} withCard summaryCard={autoFmbInputCalculation?.summary_card} />
      </div>

      <ModelComponents
        flowingPressureOptions={flowingPressureOptions}
        validationError={errorInputValidation}
        fmbInputState={autoFmbState?.inputs}
        calculateValue={autoFmbInputCalculation}
        isLoading={totalLoadingState}
        updateInputFmb={updateInputFmb}
        oilFirst={fluidModuleType === FluidType.oil}
      />
    </div>
  );
};

export default AutoFmbInput;

import { fetchApi } from "@/utils/apiFetcher";
import { importWell, importProject, moveWellBetweenGroup, getMappingHeaders } from "@/constants/apiUrl";
import { z } from "zod";
import { camelToSnakeCase } from "@/utils/general";

export type ParsedFile = {
  fileName: string;
  headers: string[];
  rows: string[][];
};

// For each header, the backend expects these two fields:
export type HeaderMapping = {
  normalizedField: string | null;
  normalizedUnit: string | null;
};

type SnakeCaseHeaderMapping = {
  normalized_field: string | null;
  normalized_unit: string | null;
};

export type Configuration = {
  well_type: string | null;
  header: Record<string, HeaderMapping>;
};

type ImportWellPayload = {
  file: File;
  override?: boolean;
  configuration: Configuration;
};

export type PromiseResult<ErrorCode> = {
  code: number;
  detail: PromiseResultDetail<ErrorCode>;
  status?: ResultStatus;
};

export type ResultStatus = "rejected" | "fulfilled";

export type PromiseResultDetail<ErrorCode> = {
  code: ErrorCode;
  message: string;
};

export type ResultState<ErrorCode> = {
  [fileIndex: number]: PromiseResult<ErrorCode>;
};

export type WellErrorCode = "validate_error" | "no_permission" | "file_exists";
export type ProjectStructureErrorCode = "validate_error";

const transformToSnakeCase = (mapping: Record<string, HeaderMapping>): Record<string, SnakeCaseHeaderMapping> => {
  return Object.fromEntries(
    Object.entries(mapping).map(([header, value]) => [
      header,
      Object.fromEntries(Object.entries(value).map(([key, val]) => [camelToSnakeCase(key), val])),
    ])
  ) as Record<string, SnakeCaseHeaderMapping>;
};

export const postDataSet = ({ file, override = false, configuration }: ImportWellPayload) => {
  const snakeCaseHeader = transformToSnakeCase(configuration.header);

  const finalConfig = {
    well_type: configuration.well_type,
    header: snakeCaseHeader,
  };

  const formData = new FormData();
  formData.append("file", file);
  formData.append("configuration", JSON.stringify(finalConfig));

  return fetchApi({
    path: importWell,
    queryStringParameters: { override },
    type: "post",
    body: formData,
  });
};

export const NormalizedHeadersSchema = z.object({
  Date: z.null(),
  "Water Volume": z.array(z.string()),
  "Oil Volume": z.array(z.string()),
  "Gas Volume": z.array(z.string()),
  "Casing Pressure": z.array(z.string()),
  "Tubing Pressure": z.array(z.string()),
  "Bottomhole Pressure": z.array(z.string()),
  "Pump Efficiency": z.null(),
  Temperature: z.array(z.string()),
  Tag: z.null(),
  "AFA-welltype": z.null(),
});

export type NormalizedHeaders = z.infer<typeof NormalizedHeadersSchema>;

export const getNormalizedHeaders = async (): Promise<NormalizedHeaders> => {
  const res = await fetchApi({
    path: getMappingHeaders,
  });
  return NormalizedHeadersSchema.parse(res.data);
};

export const formatUnit = (unit: string): React.ReactNode => {
  switch (unit) {
    case "e3m3/d":
      return <span>e³m³/d</span>;
    case "g_m3/d":
      return <span>m³/d</span>;
    case "f_m3/d":
      return <span>m³/d</span>;
    default:
      return unit;
  }
};

export const postProjectStructure = ({ file, override = false }: ImportWellPayload) => {
  const formData = new FormData();
  formData.append("file", file);

  return fetchApi({
    path: importProject,
    queryStringParameters: { override },
    type: "post",
    body: formData,
  });
};

type MoveWellGroupPayload = {
  body: {
    group_id: string;
    dataset_ids: string[];
  };
  projectId: string;
  groupId: string;
};

// Move wells between groups in a single project
export const postMoveWells = ({ body, groupId, projectId }: MoveWellGroupPayload) => {
  return fetchApi({
    path: moveWellBetweenGroup(projectId, groupId),
    type: "post",
    body,
  });
};

type ImportGenericWell = {
  file: File;
  url: string;
};

export const postGenericUpload = ({ file, url }: ImportGenericWell) => {
  const formData = new FormData();
  formData.append("file", file);

  return fetchApi<Blob>({
    path: url,
    type: "post",
    body: formData,
    responseType: "blob",
  });
};

import { boolean, nativeEnum, number, object, string, z } from "zod";

export enum FlowPressureTypeEnum {
  TubingHeadPressure = "TubingHeadPressure",
  BottomHolePressure = "BottomHolePressure",
  CasingPressure = "CasingPressure",
}

export enum ContractRateEnum {
  WaterContractRate = "WaterContractRate",
  GasContractRate = "GasContractRate",
}

export enum FormationCompressibilityCorrelationEnum {
  NewmanSandstone = "NewmanSandstone",
  NewmanLimestone = "NewmanLimestone",
  Hall = "Hall",
  Constant = "Constant",
  DobryninLaurent = "DobryninLaurent",
  DobryninWang = "DobryninWang",
}

export enum ZCorrelation {
  DranchukPurvisRobinson = "DranchukPurvisRobinson",
  BeggsBrill = "BeggsBrill",
  Papay = "Papay",
  DranchukAbouKassem = "DranchukAbouKassem",
}

export const powerLawScheme = z.object({
  permeability_a: z.number().nullable(),
  permeability_b: z.number().nullable(),
  skin_a: z.number().nullable(),
  skin_b: z.number().nullable(),
});

export type PowerLaw = z.infer<typeof powerLawScheme>;

export enum ViscosityCorrelationEnum {
  GonzalesEakin = "GonzalesEakin",
  Londono = "Londono",
  Standing = "Standing",
}

export enum WaterCompressibilityCorrelation {
  BeggsBrill = "BeggsBrill",
  McCain = "McCain",
}

export enum WaterViscosityCorrelation {
  BeggsBrill = "BeggsBrill",
  McCain = "McCain",
  LouisianaStateUniversity = "LouisianaStateUniversity",
}

export enum SkinModelEnum {
  Constant = "Constant",
  PowerLaw = "PowerLaw",
}

//    RELATIVE PERMEABILITY
export enum RelativePermeabilityCorrelationEnum {
  Burdine = "Burdine",
  ModifiedBurdine = "ModifiedBurdine",
  Corey = "Corey",
}

const relativePermeabilityCoreyScheme = z.object({
  krg_max: z.number(),
  connate_water_saturation: z.number(),
  residual_gas_saturation: z.number(),
  krw_max: z.number(),
  ng: z.number(),
  nw: z.number(),
});

const relativePermeabilityBurdineScheme = z.object({
  krg_max: z.number(),
  connate_water_saturation: z.number(),
  lamb: z.number(),
  residual_gas_saturation: z.number(),
});

const relativePermeabilityModifiedBurdineScheme = z.object({
  krg_max: z.number(),
  connate_water_saturation: z.number(),
  residual_gas_saturation: z.number(),
  lamb: z.number(),
  n: z.number(),
});

export const relativePermeabilityScheme = z.object({
  selected_relative_permeability_correlation: z.nativeEnum(RelativePermeabilityCorrelationEnum),
  parameter: relativePermeabilityCoreyScheme.or(relativePermeabilityModifiedBurdineScheme).or(relativePermeabilityBurdineScheme),
});

export type RelativePermeability = z.infer<typeof relativePermeabilityScheme>;

// Geomechanics
export enum GeomechanicModelEnum {
  NoModel = "NoModel",
  PalmerMansoori = "PalmerMansoori",
  YilmazNur = "YilmazNur",
  BurgoyneShrivastava = "BurgoyneShrivastava",
}

const matrixShrinkagePalmerMansooriScheme = z.object({
  poissons_ratio: z.number(),
  grain_compressibility: z.number(),
  langmuir_volume_strain: z.number(),
  young_module: z.number(),
  shrinkage_exponent: z.number(),
  shrinkage_factor: z.number(),
});

const matrixShrinkageYilmazNurScheme = z.object({
  permeability_modulus: z.number(),
});

const matrixShrinkageBurgoyneShrivastavaScheme = z.object({
  shrinkage_factor: z.number(),
  maximum_productivity_multiplier: z.number(),
});

export const matrixShrinkageScheme = z.object({
  selected_geomechanic_model: z.nativeEnum(GeomechanicModelEnum),
  parameter: matrixShrinkagePalmerMansooriScheme.or(matrixShrinkageYilmazNurScheme).or(matrixShrinkageBurgoyneShrivastavaScheme).nullable(),
});

export type MatrixShrinkage = z.infer<typeof matrixShrinkageScheme>;

// Well input
export enum WellInputEnum {
  Vertical = "Vertical",
  VerticalFractured = "VerticalFractured",
  Horizontal = "Horizontal",
}

// wellbore
export enum WellboreModelEnum {
  NoModel = "NoModel",
  PumpModel = "PumpModel",
}

// defined parameter
export enum FormationCompressibilityCorrelation {
  NewmanSandstone = "NewmanSandstone",
  NewmanLimestone = "NewmanLimestone",
  Hall = "Hall",
  Constant = "Constant",
  DobryninLaurent = "DobryninLaurent",
  DobryninWang = "DobryninWang",
}

export const defineParametersScheme = object({
  initial_pressure: number(),
  formation_temperature: number(),
  initial_water_saturation: number(),
  wellbore_radius: number(),
  net_pay: number(),
  porosity: number(),
  selected_formation_compressibility_correlation: nativeEnum(FormationCompressibilityCorrelation),
  formation_compressibility: number(),
});

export type DefinedParameters = z.infer<typeof defineParametersScheme>;

// Gas PVT
const gasPvtRecombinationScheme = object({
  use_gas_recombination: boolean(),
  specific_gravity_separator: number(),
  condensate_API: number(),
  condensate_gas_ratio: number(),
});

export type GasPvtRecombination = z.infer<typeof gasPvtRecombinationScheme>;

export enum GasViscosityCorrelation {
  GonzalesEakin = "GonzalesEakin",
  Londono = "Londono",
  Standing = "Standing",
}

export const gasPvtScheme = object({
  specific_gravity: number(),
  selected_z_correlation: nativeEnum(ZCorrelation),
  nitrogen: number(),
  carbon_dioxide: number(),
  hydrogen_sulphide: number(),
  selected_gas_viscosity_correlation: nativeEnum(GasViscosityCorrelation),
  section_gas_recombination: gasPvtRecombinationScheme,
});

export type GasPvt = z.infer<typeof gasPvtScheme>;

// WATER PVT
export const waterPvtScheme = object({
  salinity: number(),
  selected_water_compressibility_correlation: nativeEnum(WaterCompressibilityCorrelation),
  selected_water_viscosity_correlation: nativeEnum(WaterViscosityCorrelation),
});

export type WaterPvt = z.infer<typeof waterPvtScheme>;

// OIL PVT
export enum OilDensityCorrelation {
  BlackOil = "BlackOil",
  StandingKatz = "StandingKatz",
}

export enum OilViscosityCorrelation {
  VasquezBeggs = "VasquezBeggs",
}

export enum SolutionGasOilCorrelation {
  VasquezBeggs = "VasquezBeggs",
  Standing = "Standing",
  Glaso = "Glaso",
  Lasater = "Lasater",
  Petrosky = "Petrosky",
}

export const oilPvtScheme = object({
  oil_gravity_api: number(),
  bubble_pressure: number(),
  solution_gas_specific_gravity: number(),
  selected_oil_density_correlation: nativeEnum(OilDensityCorrelation),
  selected_oil_viscosity_correlation: nativeEnum(OilViscosityCorrelation),
  selected_solution_gas_oil_correlation: nativeEnum(SolutionGasOilCorrelation),
});
export type OilPvt = z.infer<typeof oilPvtScheme>;

// langmuir isotherm
export const langmuirIsothermScheme = object({
  langmuir_volume: number(),
  langmuir_pressure: number(),
  rock_density: number(),
  desorption_pressure: number(),
});

export type LangmuirIsotherm = z.infer<typeof langmuirIsothermScheme>;

// Response
// use in auto fmb and auto Rta

const gasPvtDataTableScheme = object({
  pressure: number().array(),
  compressibility_factor: number().array(),
  viscosity: number().array(),
  density: number().array(),
  form_vol_factor: number().array(),
  compressibility: number().array(),
});

export type GasPvtDataTable = z.infer<typeof gasPvtDataTableScheme>;

const waterPvtDataTableScheme = object({
  pressure: number().array(),
  viscosity: number().array(),
  density: number().array(),
  form_vol_factor: number().array(),
  compressibility: number().array(),
});

export type WaterPvtDataTable = z.infer<typeof waterPvtDataTableScheme>;

const oilPvtDataTableScheme = object({
  pressure: number().array(),
  viscosity: number().array(),
  density: number().array(),
  form_vol_factor: number().array(),
  compressibility: number().array(),
  solution_gas_oil_ratio: number().array(),
  superficial_tension: number().array(),
});
export type OilPvtDataTable = z.infer<typeof oilPvtDataTableScheme>;

const langmuirIsothermDataTableScheme = object({
  pressure: number().array(),
  gas_content: number().array(),
  desorption_compressibility: number().array(),
  desorption_gas_content: number(),
});

export type LangmuirIsothermDataTable = z.infer<typeof langmuirIsothermDataTableScheme>;

export const summaryCardTableScheme = object({
  parameter: string(),
  value: number(),
  unit: string(),
});

export const inputGridCalculationScheme = object({
  gas_pvt_charts: gasPvtDataTableScheme,
  water_pvt_charts: waterPvtDataTableScheme,
  oil_pvt_charts: oilPvtDataTableScheme,
  langmuir_charts: langmuirIsothermDataTableScheme.nullable(),
  summary_card: summaryCardTableScheme.array(),
});

export type SummaryCardTable = z.infer<typeof summaryCardTableScheme>;

export type InputGridCalculation = z.infer<typeof inputGridCalculationScheme>;

export enum PressureFmbDataEnum {
  TubingHeadPressure = "TubingHeadPressure",
  BottomHolePressure = "BottomHolePressure",
  CasingPressure = "CasingPressure",
  ConstantPressure = "ConstantPressure",
}

export enum WellboreFmbModelEnum {
  BeggsBrill = "BeggsBrill",
  NoModel = "NoModel",
}

const wellboreFmbParam = object({
  tubing_diameter: number(),
  tubing_roughness: number(),
  tubing_depth: number(),
  well_angle: number(),
  wellhead_temperature: number(),
});

export const wellboreInputScheme = object({
  selected_flowing_pressure: nativeEnum(PressureFmbDataEnum),
  constant_flowing_pressure: number(),
  selected_wellbore_model: nativeEnum(WellboreFmbModelEnum),
  parameters: wellboreFmbParam.nullable(),
});

export type WellboreFmbInput = z.infer<typeof wellboreInputScheme>;

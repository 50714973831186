import { useCallback } from "react";
import _ from "lodash";

import { AutoRtaState, AutoRtaAnalysis, AnalysisAutoRtaRes, analysisAutoRtaResScheme } from "@/models/gaz/autoRta";

import { useAutoRtaState } from "../AutoRtaContext";
import { usePolling } from "@/utils/apiFetcher/pollRequest";
import { AnalysisPayload } from "@/models/gaz/autoRta/State";
import { autoRtaAnalyse } from "@/constants/apiUrl";

const useAutoRtaAnalysis = () => {
  const {
    autoRtaState,
    setAutoRtaState,
    dataSet,
    project,
    fluidType,
    isLoading,
    setApiError,
    setValidationError,
    validationError,
    apiError,
    setIsLoading,
    setPollStatus,
    setProgress,
    analysisCalculation,
    setAnalysisCalculation,
    setAutoRtaForecastCalculation,
    expandParameter,
    setHaveChangeValue,
  } = useAutoRtaState();

  const { createPoll, canCancelPoll, onCancelPoll } = usePolling({
    setApiError,
    setErrorValidation: setValidationError,
    setLoadingState: setIsLoading,
    setProgressStatus: (val) => {
      setProgress(val.progress ?? null);
      setPollStatus(val.pollStatus);
    },
    apiError,
  });

  // previous key will always be available
  const onChangeAnalysisInput = (value: any, firstKey: string, secKey?: string) => {
    setValidationError([]);
    setAutoRtaState((prev) => {
      if (!prev) return prev;
      const newState: { [key: string]: any } = _.cloneDeep(prev.analysis);
      if (secKey) {
        newState[firstKey ?? ""][secKey ?? ""] = value;
      } else {
        newState[firstKey] = value;
      }
      return {
        ...prev,
        analysis: newState as AutoRtaAnalysis,
      } as AutoRtaState;
    });
    // Reset forecastCalculation
    setHaveChangeValue(true);
    setAnalysisCalculation(undefined); //not sure if this is needed
    setAutoRtaForecastCalculation(undefined);
  };

  // calculate analysis
  const onClickCalculateAnalysis = useCallback(async () => {
    if (!autoRtaState?.analysis || !project?.id || !dataSet || !autoRtaState?.inputs) return;

    try {
      const response = await createPoll<AnalysisAutoRtaRes, AnalysisPayload>({
        path: autoRtaAnalyse(project?.id, fluidType),
        body: {
          data_set_ids: dataSet.map((data) => data.id),
          analysis: autoRtaState.analysis,
          inputs: autoRtaState.inputs,
        },
        withTaskInfo: true,
        type: "post",
      });
      if (response.task_result) {
        const parsed = analysisAutoRtaResScheme.parse(response.task_result);
        setAnalysisCalculation(parsed.analysis_result);
        if (!_.isEqual(parsed.analysis_inputs, autoRtaState?.analysis)) {
          setAutoRtaState((prev) => {
            setAutoRtaForecastCalculation(undefined);
            if (!prev) return prev;
            return {
              ...prev,
              analysis: parsed.analysis_inputs,
            };
          });
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  }, [
    autoRtaState?.analysis,
    autoRtaState?.inputs,
    project?.id,
    dataSet,
    createPoll,
    fluidType,
    setAutoRtaState,
    setAnalysisCalculation,
    setAutoRtaForecastCalculation,
  ]);

  return {
    analysisInputState: autoRtaState?.analysis,
    analysisCalculation,
    onChangeAnalysisInput,
    loadingState: isLoading,
    onClickCalculateAnalysis,
    validationError,
    canCancelPoll,
    onCancelPoll,
    expandParameter,
  };
};

export default useAutoRtaAnalysis;

import { capitalizeFirstLetter, formatToEngineeringNotation } from "@/utils/general";

import symbolUnits from "@/constants/symbolUnit";

import { tableCellStyle, tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";
import { transformHeaderOrNotation } from "@/utils/cellRendering";

import dictionary from "@/constants/dictionary";
import { Row } from "@silevis/reactgrid";

export const generateDataTableColumnBase = (headerList: string[]) => {
  return headerList.map((header) => {
    return {
      columnId: header,
    };
  });
};

export const generateDataTableRowCalculation = ({
  headerList,
  symbols,
  units,
  dataTable,
}: {
  headerList: string[];
  symbols?: Record<string, string>;
  units?: Record<string, string>;
  dataTable?: { [key: string]: any };
}) => {
  if (!dataTable) return [];

  const constantInputsRows = [
    {
      rowId: "header",
      height: 50,
      cells: headerList.map((head: string) => {
        const item = symbols?.[head] ?? "";

        return {
          type: "custom",
          isLatex: item,
          text: symbolUnits?.[item] ?? capitalizeFirstLetter(transformHeaderOrNotation(head)),
          style: tableHeaderStyle,
        };
      }),
    },

    {
      rowId: "notation",
      height: 30,
      cells: headerList.map((head: string) => {
        return {
          type: "header",
          text: transformHeaderOrNotation(units?.[head]),
          style: tableHeaderNotationStyle,
        };
      }),
    },
    ...dataTable.dates.map((_: any, index: number) => {
      return {
        key: index,
        rowId: index + 1,
        cells: headerList.map((head) => {
          const result: { [key: string]: any } = {
            type: head === "dates" ? "date" : "text",
            nonEditable: true,
            style: tableCellStyle,
          };
          let text = dataTable?.[head]?.[index];
          if (head === "dates") {
            result.date = new Date(text);
            result.format = Intl.DateTimeFormat();
          } else {
            result.text = formatToEngineeringNotation(text);
          }
          return result;
        }),
      };
    }),
  ] as Row[];
  return constantInputsRows;
};

export const generateDataTableColumn = (selectedCase: string, dataTable?: { [key: string]: any }) => {
  if (!dataTable) return [];

  return Object.keys(dataTable[selectedCase]).map((item) => {
    const wordCount = item.split("_").length;
    return {
      columnId: item,
      width: wordCount > 2 ? 180 : 140,
    };
  });
};

export const generateDataTableRow = ({
  column,
  selectedCase,
  symbols,
  units,
  dataTable,
}: {
  column: { columnId: string }[];
  symbols?: Record<string, string>;
  units?: Record<string, string>;
  selectedCase: string;
  dataTable?: { [key: string]: any };
}) => {
  if (!dataTable) return [];

  return [
    {
      rowId: "header",
      cells: column.map((column) => {
        const item = symbols?.[column.columnId] ?? "";
        return {
          type: "custom",
          isLatex: item,
          text: symbolUnits?.[item] ?? capitalizeFirstLetter(transformHeaderOrNotation(column.columnId)),
          style: tableHeaderStyle,
          nonEditable: true,
        };
      }),
      height: 50,
    },
    {
      rowId: "notation",
      cells: column.map((column) => ({
        type: "text",
        text: transformHeaderOrNotation(units?.[column.columnId]) ?? dictionary.suffix[column.columnId] ?? "",
        style: tableHeaderNotationStyle,
        nonEditable: true,
      })),
      height: 35,
    },
    ...dataTable[selectedCase].flowing_pressure.map((_: any, index: string | number) => {
      return {
        rowId: index,
        cells: column.map((column) => {
          const val = dataTable?.[selectedCase]?.[column.columnId]?.[index] ?? 0;

          return {
            type: "text",
            text: formatToEngineeringNotation(val ?? ""),
            style: tableCellStyle,
            nonEditable: true,
          };
        }),
      };
    }),
  ];
};

import { useCallback, useMemo, useState } from "react";
import { DialogEnum } from "../types";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox as CheckboxMui,
  Card,
  CardContent,
} from "@mui/material";
import DialogMui from "@mui/material/Dialog";

import InputField from "@/components/fields/InputField";
import { Project, TransferProject } from "@/model";
import DropdownField from "@/components/fields/DropdownField";
import dictionary from "@/constants/dictionary";
import { mapStringArrayToDropdown } from "@/utils/general";
import { DateTime } from "luxon";
import useThemeStyling from "@/utils/useThemeStyling";
import { useTimer } from "@/utils/dateTime";

interface ProjectDialogProps {
  selectedKey: string | undefined;
  copyFromSelected: boolean;
  setCopyFromSelected: (copy: boolean) => void;
  isLoading: boolean;
  createNewProject: (name: string) => Promise<void> | void;
  deleteProject: () => Promise<void> | void;
  editExistingProject: (name: string) => Promise<void> | void;

  activeDialog?: DialogEnum;
  setActiveDialog: (val: DialogEnum | undefined) => void;

  transferProject: (userId: string) => void;
  finalizeTransferProject: (projectId: string, accept: boolean, notDismissDialog?: boolean) => Promise<void> | void;
  userIdList?: string[];
  project?: Project;
  selectedPendingProject: TransferProject[];
  setSelectedPendingProjectList: React.Dispatch<React.SetStateAction<TransferProject[]>>;
}

type PendingProjectDialogProps = {
  isLoading: boolean;
  activeDialog?: DialogEnum;
  setActiveDialog: (val: DialogEnum | undefined) => void;
  finalizeTransferProject: (projectId: string, accept: boolean, notDismissDialog?: boolean) => Promise<void> | void;
  setSelectedPendingProjectList: React.Dispatch<React.SetStateAction<TransferProject[]>>;
};

const PendingProjectOutDialog = ({
  currentPendingProject,
  isLoading,
  setActiveDialog,
  activeDialog,
  finalizeTransferProject,
  setSelectedPendingProjectList,
}: PendingProjectDialogProps & {
  currentPendingProject: TransferProject;
}) => {
  const { palette } = useThemeStyling();

  const utcDate = DateTime.fromISO(`${currentPendingProject.expire_at}Z`);

  const { expiryTime, haveExpire } = useTimer(utcDate.toMillis());

  const onCloseDialog = useCallback(() => {
    setActiveDialog(undefined);

    if (haveExpire) window.location.reload();
    setSelectedPendingProjectList([]);
  }, [haveExpire, setActiveDialog, setSelectedPendingProjectList]);

  return (
    <DialogMui open={activeDialog === DialogEnum.PENDING_PROJECT_OUT} keepMounted onClose={onCloseDialog}>
      <DialogTitle className="primaryColor">
        <b>{dictionary.transferProject.pendingTransferProject}</b>
      </DialogTitle>
      {!haveExpire ? (
        <>
          <DialogContent
            style={{
              width: 500,
            }}
          >
            <Card>
              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div style={{ color: palette.primary.main, fontWeight: "bold" }}>{currentPendingProject.name}</div>
                  <div>
                    {dictionary.transferProject.sentTo}: {currentPendingProject.target_user}
                  </div>
                </div>
                <div
                  style={{
                    color: palette.error.main,
                    fontSize: 12,
                  }}
                >
                  {dictionary.transferProject.expireIn}: {expiryTime}
                </div>
              </CardContent>
            </Card>
            <DialogContentText style={{ marginTop: 10 }}>Warning: This operation cannot be undone!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCloseDialog} disabled={isLoading} variant="outlined">
              Close
            </Button>
            <Button
              style={{ color: "white", marginTop: 10, marginBottom: 10 }}
              variant="contained"
              disabled={isLoading || !currentPendingProject}
              onClick={() => {
                if (currentPendingProject) finalizeTransferProject(currentPendingProject.id, false);
              }}
            >
              Cancel Transfer
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent
            style={{
              width: 500,
            }}
          >
            <div>{dictionary.transferProject.pendingProjectExpired}</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCloseDialog} disabled={isLoading} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </DialogMui>
  );
};

const PendingProjectInItem = ({
  currentPendingProject,
  finalizeTransferProject,
  isLoading,
  isMultiple,
}: {
  isMultiple: boolean;
  currentPendingProject: TransferProject;
  isLoading: boolean;
  finalizeTransferProject: (projectId: string, accept: boolean, notDismissDialog?: boolean) => Promise<void> | void;
}) => {
  const { palette } = useThemeStyling();
  const utcDate = DateTime.fromISO(`${currentPendingProject.expire_at}Z`);
  const { haveExpire, expiryTime } = useTimer(utcDate.toMillis());

  return (
    <Card
      style={{
        marginBottom: 10,
      }}
    >
      <CardContent
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div style={{ color: palette.primary.main, fontWeight: "bold" }}>{currentPendingProject.name}</div>
          <div>
            {dictionary.transferProject.sender}: {currentPendingProject.original_user}
          </div>
        </div>
        <div
          style={{
            color: palette.error.main,
            fontSize: 12,
          }}
        >
          {dictionary.transferProject.expireIn}: {expiryTime}
        </div>
      </CardContent>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: "flex",
          justifyContent: "right",
        }}
      >
        <Button
          color={"error"}
          onClick={() => finalizeTransferProject(currentPendingProject.id, false, isMultiple)}
          disabled={isLoading || haveExpire}
          variant="contained"
        >
          Reject
        </Button>
        <Button
          style={{ marginLeft: 10, marginRight: 10, color: "white" }}
          color={"success"}
          variant="contained"
          disabled={isLoading || haveExpire}
          onClick={() => {
            finalizeTransferProject(currentPendingProject.id, true, isMultiple);
          }}
        >
          Accept
        </Button>
      </div>
    </Card>
  );
};

const PendingProjectInDialog = ({
  finalizeTransferProject,
  isLoading,
  setActiveDialog,
  activeDialog,
  selectedPendingProjectList,
  setSelectedPendingProjectList,
}: PendingProjectDialogProps & {
  selectedPendingProjectList: TransferProject[];
}) => {
  const onCloseDialog = useCallback(() => {
    setActiveDialog(undefined);
    setSelectedPendingProjectList([]);
  }, [setActiveDialog, setSelectedPendingProjectList]);

  return (
    <DialogMui open={activeDialog === DialogEnum.PENDING_PROJECT_IN} keepMounted onClose={onCloseDialog}>
      <DialogTitle className="primaryColor">
        <b>{dictionary.transferProject.pendingTransferProject}</b>
      </DialogTitle>
      <DialogContent
        style={{
          width: 500,
          maxHeight: 350,
        }}
      >
        <DialogContentText style={{ marginBottom: 10 }}>{dictionary.transferProject.receiveProjectTransfer}</DialogContentText>
        {selectedPendingProjectList.map((project) => (
          <PendingProjectInItem
            isMultiple={selectedPendingProjectList.length > 1}
            isLoading={isLoading}
            key={project.id}
            currentPendingProject={project}
            finalizeTransferProject={finalizeTransferProject}
          />
        ))}
      </DialogContent>

      <DialogActions>
        <Button onClick={onCloseDialog} disabled={isLoading} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </DialogMui>
  );
};

const ProjectDialog = ({
  selectedKey,
  copyFromSelected,
  setCopyFromSelected,
  isLoading,
  createNewProject,
  deleteProject,
  editExistingProject,
  activeDialog,
  setActiveDialog,
  transferProject,
  userIdList,
  project,
  finalizeTransferProject,
  selectedPendingProject,
  setSelectedPendingProjectList,
}: ProjectDialogProps) => {
  const [name, setName] = useState("");

  const [selectedTransferUser, setSelectedTransferUser] = useState<string>();

  const optionUserList = mapStringArrayToDropdown(userIdList) ?? [];

  const currentPendingProject = useMemo(() => {
    if (selectedPendingProject.length === 0) return null;
    return selectedPendingProject[0];
  }, [selectedPendingProject]);

  return (
    <>
      {/* new project dialog */}
      <DialogMui
        open={activeDialog === DialogEnum.NEW_PROJECT}
        keepMounted
        onClose={() => {
          setActiveDialog(undefined);
        }}
      >
        <DialogTitle className="primaryColor">{"New Project"}</DialogTitle>
        <DialogContent
          style={{
            maxWidth: 350,
          }}
        >
          <DialogContentText>
            Please enter a name for the new project. You can optionally copy groups and settings from the currently selected project.
          </DialogContentText>
          <InputField
            label="New project name"
            required
            placeholder="Enter a project name..."
            disabled={isLoading}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                createNewProject(name);
                setName("");
              }
            }}
            debounceDelay={10}
            value={name}
            type="text"
            onChange={(v) => setName(String(v ?? ""))}
          />
          {selectedKey && (
            <FormControlLabel
              control={
                <CheckboxMui
                  onChange={(e) => {
                    setCopyFromSelected(e.target.checked);
                  }}
                  checked={copyFromSelected}
                  size="small"
                  data-testid="Copy selected project"
                />
              }
              disabled={isLoading}
              label={"Copy selected project"}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="create-project"
            style={{ color: "white", marginTop: 10, marginBottom: 10 }}
            variant="contained"
            disabled={isLoading || !name}
            onClick={() => {
              createNewProject(name);
              setName("");
            }}
          >
            Create
          </Button>

          <Button
            onClick={() => {
              setActiveDialog(undefined);
            }}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogMui>

      {/* edit project dialog */}
      <DialogMui
        open={activeDialog === DialogEnum.EDIT_PROJECT}
        keepMounted
        onClose={() => {
          setActiveDialog(undefined);
        }}
      >
        <DialogTitle className="primaryColor">{"Edit Project"}</DialogTitle>
        <DialogContent
          style={{
            maxWidth: 350,
          }}
        >
          <DialogContentText>You can update the project name below. Click save when you are done with this project. </DialogContentText>
          <InputField
            label="New project name"
            required
            placeholder="Enter a project name..."
            disabled={isLoading}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                editExistingProject(name);
                setName("");
              }
            }}
            debounceDelay={10}
            value={name}
            onChange={(v) => setName(String(v ?? ""))}
            type="text"
          />

          <span style={{ fontSize: 10 }}>Tip: Use the Enter key to save changes.</span>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "white", marginTop: 10, marginBottom: 10 }}
            variant="contained"
            disabled={!name}
            onClick={() => {
              editExistingProject(name);
              setName("");
            }}
          >
            Save
          </Button>

          <Button
            onClick={() => {
              setActiveDialog(undefined);
            }}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogMui>

      <DialogMui
        open={activeDialog === DialogEnum.DELETE_PROJECT}
        keepMounted
        onClose={() => {
          setActiveDialog(undefined);
        }}
      >
        <DialogTitle className="primaryColor">{"Delete Project"}</DialogTitle>
        <DialogContent
          style={{
            maxWidth: 350,
          }}
        >
          <DialogContentText>Warning: This operation cannot be undone!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "white", marginTop: 10, marginBottom: 10 }}
            variant="contained"
            disabled={isLoading}
            onClick={() => {
              deleteProject();
            }}
          >
            Delete
          </Button>

          <Button
            onClick={() => {
              setActiveDialog(undefined);
            }}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogMui>

      <DialogMui
        open={activeDialog === DialogEnum.TRANSFER_PROJECT}
        keepMounted
        onClose={() => {
          setActiveDialog(undefined);
        }}
      >
        <DialogTitle className="primaryColor">
          {`Transfer `}
          <b>{project?.name} </b>?
        </DialogTitle>
        <DialogContent
          style={{
            maxWidth: 350,
          }}
        >
          <DialogContentText>Warning: This operation cannot be undone!</DialogContentText>

          {userIdList && (
            <DropdownField
              displayEmpty
              dataTestId="user-list-dropdown"
              label={dictionary.nav.targetUser}
              options={optionUserList}
              selectedKey={selectedTransferUser ?? ""}
              onChange={(val) => setSelectedTransferUser(val)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "white", marginTop: 10, marginBottom: 10 }}
            variant="contained"
            disabled={isLoading || !selectedTransferUser}
            onClick={() => {
              if (selectedTransferUser) transferProject(selectedTransferUser);
            }}
          >
            Transfer
          </Button>

          <Button
            onClick={() => {
              setActiveDialog(undefined);
            }}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogMui>

      {currentPendingProject && (
        <PendingProjectOutDialog
          setSelectedPendingProjectList={setSelectedPendingProjectList}
          finalizeTransferProject={finalizeTransferProject}
          isLoading={isLoading}
          setActiveDialog={setActiveDialog}
          activeDialog={activeDialog}
          currentPendingProject={currentPendingProject}
        />
      )}
      <PendingProjectInDialog
        setSelectedPendingProjectList={setSelectedPendingProjectList}
        setActiveDialog={setActiveDialog}
        finalizeTransferProject={finalizeTransferProject}
        isLoading={isLoading}
        activeDialog={activeDialog}
        selectedPendingProjectList={selectedPendingProject}
      />
    </>
  );
};

export default ProjectDialog;

import { autoFmbInputCalcApi, autoFmbInputCalcApiV2 } from "@/constants/apiUrl";
import { fetchApi } from "@/utils/apiFetcher";

import { FluidType } from "@/models/Generic";
import { InputGridCalculation } from "@/models/InputGeneric";

import { FmbInputState } from "../input";

type CalculateInputFmbPayload = {
  data_set_ids: string[];
  inputs: FmbInputState;
};

export const postCalculateInputFmb = (projectId: string, fluidModuleType: FluidType, body: CalculateInputFmbPayload) => {
  if (fluidModuleType === FluidType.oil) {
    return fetchApi<InputGridCalculation, CalculateInputFmbPayload>({
      path: autoFmbInputCalcApiV2(projectId),
      type: "post",
      body,
    });
  }

  return fetchApi<InputGridCalculation, CalculateInputFmbPayload>({
    path: autoFmbInputCalcApi(projectId),
    type: "post",
    body,
  });
};

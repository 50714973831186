import { fetchApi } from "@/utils/apiFetcher";
import { autoRtaInput, autoRtaSaveInput } from "@/constants/apiUrl";
import { AutoRtaInputGrid } from "../InputGrid";
import { InputGridCalculation } from "@/models/InputGeneric";
import { AutoRtaAnalysis } from "../Analysis";
import { AutoRtaForecastV2 } from "../Forecast";

type CalculateInputPayload = {
  inputs: AutoRtaInputGrid;
  data_set_ids: string[];
};

export const postCalculateInputAutoRta = (projectId: string, type: string, payload: CalculateInputPayload) => {
  return fetchApi<InputGridCalculation, CalculateInputPayload>({
    path: autoRtaInput(projectId, type),
    body: payload,
    type: "post",
  });
};

type SaveInputPayload = {
  data_set_ids: string[];
  inputs: AutoRtaInputGrid;
  analysis: AutoRtaAnalysis;
  forecast: AutoRtaForecastV2;
};
export const postSaveInputAutoRta = (projectId: string, type: string, payload: SaveInputPayload) => {
  return fetchApi<string, SaveInputPayload>({
    path: autoRtaSaveInput(projectId, type),
    body: payload,
    type: "post",
  });
};

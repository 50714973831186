import { useEffect, useMemo, useRef, useState } from "react";
import dictionary from "@/constants/dictionary";

export const convertDateToUtcTimeZoneIsoString = (date?: Date) => {
  try {
    return date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)).toISOString() : undefined;
  } catch {
    return "";
  }
};

type TUseTimer = {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
};

type TimerProps = {
  haveExpire: boolean;
  expiryTime: string;
} & TUseTimer;

const DAYS_IN_MS = 1000 * 60 * 60 * 24;
const HOURS_IN_MS = 1000 * 60 * 60;
const MIN_IN_MS = 1000 * 60;
const SEC_IN_MS = 1000;

const formatNumber = (num: number) => {
  return `${num}`;
};

const getTimeDiff = (diffInMSec: number): TUseTimer => {
  let diff = diffInMSec;
  const days = Math.floor(diff / DAYS_IN_MS); // Give the remaining days
  diff -= days * DAYS_IN_MS; // Subtract passed days
  const hours = Math.floor(diff / HOURS_IN_MS); // Give remaining hours
  diff -= hours * HOURS_IN_MS; // Subtract hours
  const minutes = Math.floor(diff / MIN_IN_MS); // Give remaining minutes
  diff -= minutes * MIN_IN_MS; // Subtract minutes
  const seconds = Math.floor(diff / SEC_IN_MS); // Give remaining seconds
  return {
    days: formatNumber(days), // Format everything into the return type
    hours: formatNumber(hours),
    minutes: formatNumber(minutes),
    seconds: formatNumber(seconds),
  };
};

export const useTimer = (targetTime: number): TimerProps => {
  const [timeLeft, setTimeLeft] = useState(0);
  const haveStarted = useRef(false);

  useEffect(() => {
    if (targetTime) {
      if (timeLeft === 0) setTimeLeft(targetTime - Date.now());

      const id = setTimeout(() => {
        setTimeLeft((prev) => prev - 1000);
      }, 1000);

      return () => {
        clearTimeout(id);
      };
    }
  }, [targetTime, timeLeft]);

  const resultTimeLeft = getTimeDiff(timeLeft);
  useEffect(() => {
    if (haveStarted.current) return;
    if (resultTimeLeft?.minutes !== "0" || resultTimeLeft?.seconds !== "0") {
      haveStarted.current = true;
    }
  }, [resultTimeLeft]);

  const haveExpire = resultTimeLeft?.hours === "0" && resultTimeLeft?.minutes === "0" && resultTimeLeft?.seconds === "0" && haveStarted.current;

  // only show minute and second if it is less than 1 hour
  const expiryTime: string = useMemo(() => {
    const hourString = resultTimeLeft.hours !== "0" ? `${resultTimeLeft.hours} ${dictionary.time.hours}` : "";
    const minString = resultTimeLeft.minutes !== "0" ? `${resultTimeLeft.minutes} ${dictionary.time.minutes}` : "";
    const secString = resultTimeLeft.seconds !== "0" && hourString === "" ? `${resultTimeLeft.seconds} ${dictionary.time.seconds}` : "";

    return `${hourString} ${minString} ${secString}`;
  }, [resultTimeLeft.hours, resultTimeLeft.minutes, resultTimeLeft.seconds]);

  return {
    ...getTimeDiff(timeLeft),
    haveExpire,
    expiryTime,
  };
};

export function parseISOToDate(dateTime: string): string {
  const date = new Date(dateTime);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

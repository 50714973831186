import { calculateEconomics, initializeEconomics } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { ModuleId } from "@/model";
import { EconomicsOption, EconomicsResult, EconomicState } from "./state";

type InitializeEconomicsPayload = {
  data_set_ids: string[];
};

export const postInitializeEconomics = (projectId: string, moduleId: ModuleId, body: InitializeEconomicsPayload) => {
  return fetchApi<EconomicState, InitializeEconomicsPayload>({
    path: initializeEconomics(projectId, moduleId),
    type: "post",
    body,
  });
};

type CalculateEconomicsPayload = {
  data_set_ids: string[];
  economic_option: EconomicsOption;
};

export const postCalculateEconomics = (projectId: string, moduleId: ModuleId, body: CalculateEconomicsPayload) => {
  return fetchApi<EconomicsResult, CalculateEconomicsPayload>({
    path: calculateEconomics(projectId, moduleId),
    type: "post",
    body,
  });
};

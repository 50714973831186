import { ParsedFile } from "@/models/wells";
import * as XLSX from "xlsx";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";

const MAX_PREVIEW_ROWS = 51; //50 rows + 1 header row
export const parseCsvText = (text: string, fileName: string): ParsedFile => {
  const lines = text.split(/\r\n|\n/).filter(Boolean);
  if (lines.length === 0) return { fileName, headers: [], rows: [] };

  const headerLine = lines[0];
  const rawHeaders = headerLine.split(",").map((h) => h.trim());

  const validColumnIndices = rawHeaders.reduce<number[]>((acc, header, idx) => {
    if (header !== "") acc.push(idx);
    return acc;
  }, []);
  const filteredHeaders = validColumnIndices.map((idx) => rawHeaders[idx]);

  const rows = lines.slice(1, MAX_PREVIEW_ROWS).map((line) => {
    const cells = line.split(",").map((cell) => cell.trim());
    return validColumnIndices.map((idx) => cells[idx] || "");
  });

  return {
    fileName,
    headers: filteredHeaders,
    rows,
  };
};

export const parseExcelFile = (data: ArrayBuffer, fileName: string): ParsedFile => {
  const workbook = XLSX.read(data, { type: "array" });
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];

  const allRows = XLSX.utils.sheet_to_json<string[]>(sheet, {
    header: 1,
    raw: false,
    dateNF: "yyyy/mm/dd",
  });

  if (allRows.length === 0) return { fileName, headers: [], rows: [] };

  const rawHeaders = (allRows[0] || []).map((h) => (h || "").toString().trim());

  const validColumnIndices = rawHeaders.reduce<number[]>((acc, header, idx) => {
    if (header !== "") acc.push(idx);
    return acc;
  }, []);
  const filteredHeaders = validColumnIndices.map((idx) => rawHeaders[idx]);

  const dataRows = allRows.slice(1, MAX_PREVIEW_ROWS).map((row) => {
    return validColumnIndices.map((idx) => {
      const cellValue = row[idx];
      return cellValue === null || cellValue === undefined ? "" : cellValue.toString().trim();
    });
  });

  return {
    fileName,
    headers: filteredHeaders,
    rows: dataRows,
  };
};

export const parseFile = (file: File): Promise<ParsedFile> => {
  return new Promise<ParsedFile>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const isCsv = file.name.toLowerCase().endsWith(".csv");
        if (!event.target?.result) return reject(new Error("No file content."));

        let parsed: ParsedFile;

        if (isCsv) {
          const text = event.target.result as string;
          parsed = parseCsvText(text, file.name);
        } else {
          const data = event.target.result as ArrayBuffer;
          parsed = parseExcelFile(data, file.name);
        }

        resolve(parsed);
      } catch (err) {
        console.error(`Error parsing file ${file.name}:`, err);
        reject(err);
      }
    };

    reader.onerror = (e) => {
      console.error(`Error reading file ${file.name}:`, e.target?.error);
      reject(e.target?.error);
    };

    if (file.name.toLowerCase().endsWith(".csv")) {
      reader.readAsText(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });
};

// ------ HeaderMappingDialog Helper ------
dayjs.extend(customParseFormat);
export type DateFormat = "YYYY/MM/DD" | "DD/MM/YYYY" | "YYYY-MM-DD" | "DD-MM-YYYY" | "YYYY.MM.DD" | "DD.MM.YYYY";
export const DATE_OPTIONS: readonly DateFormat[] = ["YYYY/MM/DD", "DD/MM/YYYY", "YYYY-MM-DD", "DD-MM-YYYY", "YYYY.MM.DD", "DD.MM.YYYY"] as const;
export const DATE_FORMAT_TO_PYTHON: Record<DateFormat, string> = {
  "YYYY-MM-DD": "%Y-%m-%d",
  "DD-MM-YYYY": "%d-%m-%Y",
  "YYYY/MM/DD": "%Y/%m/%d",
  "DD/MM/YYYY": "%d/%m/%Y",
  "YYYY.MM.DD": "%Y.%m.%d",
  "DD.MM.YYYY": "%d.%m.%Y",
};
export const PYTHON_TO_DATE_FORMAT: Record<string, DateFormat> = {
  "%Y-%m-%d": "YYYY-MM-DD",
  "%d-%m-%Y": "DD-MM-YYYY",
  "%Y/%m/%d": "YYYY/MM/DD",
  "%d/%m/%Y": "DD/MM/YYYY",
  "%Y.%m.%d": "YYYY.MM.DD",
  "%d.%m.%Y": "DD.MM.YYYY",
};
export const MANUAL_WELL_TYPE_OPTIONS = ["Gas", "Water", "Oil"];
export const DIALOG_CONSTANTS = {
  NOT_USED: "< Not Used >",
  CHOOSE_WELL_TYPE: "< Choose Well Type >",
  CHOOSE_UNIT: "< Choose Unit >",
  UNRECOGNIZED_DATE: "< Unrecognized Date Format >",
  DATE_FIELD: "Date",
  TAG_FIELD: "Tag",
};

export const detectDateFormat = (sampleDate: string): DateFormat | null => {
  if (!sampleDate) return null;

  // Try each format in strict mode
  for (const fmt of DATE_OPTIONS) {
    if (dayjs(sampleDate, fmt, true).isValid()) {
      return fmt;
    }
  }
  return null;
};

export const validateDateFormat = (samples: string[], detectedFormat: DateFormat | null): boolean => {
  if (!detectedFormat || samples.length === 0) return false;

  // All must parse in strict mode
  return samples.every((sample) => {
    return dayjs(sample, detectedFormat, true).isValid();
  });
};

export const getDateUnitFromFiles = (parsedFiles: ParsedFile[], header: string): string | null => {
  const allSamples: string[] = [];
  parsedFiles.forEach((file) => {
    const colIndex = file.headers.indexOf(header);
    if (colIndex >= 0) {
      file.rows.forEach((row) => {
        const cell = (row[colIndex] || "").trim();
        if (cell) allSamples.push(cell);
      });
    }
  });

  if (!allSamples.length) return DIALOG_CONSTANTS.UNRECOGNIZED_DATE;

  // Check the first sample
  const guessedFormat = detectDateFormat(allSamples[0]);
  if (!guessedFormat) return DIALOG_CONSTANTS.UNRECOGNIZED_DATE;

  // If all samples conform, great; otherwise return null
  const isValid = validateDateFormat(allSamples, guessedFormat);
  return isValid ? guessedFormat : null;
};

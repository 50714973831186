import { useMemo, useState } from "react";
import { Row } from "@silevis/reactgrid";
import { AnalysisFmbGasTable, ManualPzDataTable } from "@/models/autoFmb";

import dictionary from "@/constants/dictionary";
import symbolUnits from "@/constants/symbolUnit";

import { tableCellStyle, tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";
import { capitalizeFirstLetter, formatToEngineeringNotation } from "@/utils/general";
import { transformHeaderOrNotation } from "@/utils/cellRendering";

import { caseKey } from "../../../constants";
import { generateDataTableColumn, generateDataTableRow } from "../../utils";

type useGasDataTableProps = {
  dataTable?: AnalysisFmbGasTable;
  symbols?: Record<string, string>;
  units?: Record<string, string>;
};

export const pointManualDataTableColumn = [
  {
    columnId: "cumulative_production",
    width: 150,
    label: dictionary.fmb.cumulativeProduction,
    notation: dictionary.suffix.cumulativeProduction,
  },
  {
    columnId: "static_pressure",
    width: 150,
    label: dictionary.fmb.staticPressure,
    notation: dictionary.suffix.staticPressure,
  },
  {
    columnId: "z",
    width: 150,
    label: dictionary.fmb.z,
    notation: "",
  },
  {
    columnId: "z_star",
    width: 150,
    label: dictionary.fmb.zStar,
    notation: "",
  },
  {
    columnId: "p_on_z_star",
    width: 150,
    label: dictionary.fmb.pzStar,
    notation: dictionary.suffix.pz,
  },
];

const useGasDataTable = ({ dataTable, symbols, units }: useGasDataTableProps) => {
  const [selectedCase, setSelectedCase] = useState<string>(caseKey[0]);

  // data table
  const dataTableColumn = useMemo(() => {
    return generateDataTableColumn(selectedCase, dataTable);
  }, [dataTable, selectedCase]);

  const dataTableRow: Row<any>[] = useMemo(() => {
    return generateDataTableRow({
      column: dataTableColumn,
      selectedCase,
      symbols,
      units,
      dataTable,
    });
  }, [dataTable, dataTableColumn, selectedCase, symbols, units]);

  // pz point data table
  const pzPointDataTableRow: Row<any>[] = useMemo(() => {
    if (!dataTable) return [];

    return [
      {
        rowId: "header",
        cells: pointManualDataTableColumn.map((column) => {
          const item = symbols?.[column.columnId] ?? "";

          return {
            type: "custom",
            isLatex: item,
            text: item ? symbolUnits[item] : capitalizeFirstLetter(transformHeaderOrNotation(column.columnId)),
            style: tableHeaderStyle,
            nonEditable: true,
          };
        }),
        height: 50,
      },
      {
        rowId: "notation",
        cells: pointManualDataTableColumn.map((column) => {
          const item = units?.[column.columnId] ?? "";

          return {
            type: "custom",
            text: symbolUnits[item] ? transformHeaderOrNotation(symbolUnits[item]) : column.notation,
            style: tableHeaderNotationStyle,
            nonEditable: true,
          };
        }),
        height: 35,
      },
      ...(dataTable?.p_on_z_manual_table?.cumulative_production.map((_, index) => {
        return {
          rowId: index,
          cells: pointManualDataTableColumn.map((column) => {
            const val = dataTable?.p_on_z_manual_table?.[column.columnId as keyof ManualPzDataTable][index];

            return {
              type: "text",
              text: formatToEngineeringNotation(val ?? ""),
              style: tableCellStyle,
              nonEditable: true,
            };
          }),
        };
      }) ?? []),
    ];
  }, [dataTable, symbols, units]);

  return {
    dataTableColumn,
    dataTableRow,
    setSelectedCase,
    selectedCase,
    pzPointDataTableRow,
  };
};

export default useGasDataTable;

import { useMemo } from "react";

import { AnalysisFmbResult, AutoFmbState } from "@/models/autoFmb";
import useThemeStyling from "@/utils/useThemeStyling";

import { FluidType } from "@/models/Generic";
import { FossilyticsChartLine } from "@/components/FossilyticsChart";

type UseAutoFmbAnalysisChartProps = {
  analysisResult?: AnalysisFmbResult;
  autoFmbState?: AutoFmbState;
  fluidType: FluidType;
};

export type AnalysisChartFmbProps = {
  isLoading: boolean;
  analysisResult?: AnalysisFmbResult;
  onDragChartHandlebar: (l: number[][] | [string, number][], _i: number, _isEnd?: boolean, line?: FossilyticsChartLine) => void;
  autoFmbState?: AutoFmbState;
  tabIndex: number;
  fluidType: FluidType;
};

const useAutoFmbAnalysisChart = ({ analysisResult, autoFmbState, fluidType }: UseAutoFmbAnalysisChartProps) => {
  const { palette } = useThemeStyling();

  const chartResult = useMemo(() => {
    return analysisResult?.analysis_results;
  }, [analysisResult]);

  const colorScheme = useMemo(() => {
    if (fluidType === FluidType.oil) return [palette.customColor.greenLight, palette.customColor.green, palette.customColor.greenDark];
    return [palette.customColor.pinkLight, palette.customColor.red, palette.customColor.redDark];
  }, [
    fluidType,
    palette.customColor.green,
    palette.customColor.greenDark,
    palette.customColor.greenLight,
    palette.customColor.pinkLight,
    palette.customColor.red,
    palette.customColor.redDark,
  ]);

  const symbols = useMemo(() => {
    return autoFmbState?.symbols_key;
  }, [autoFmbState?.symbols_key]);

  const units = useMemo(() => {
    return autoFmbState?.units_key;
  }, [autoFmbState?.units_key]);

  return {
    colorScheme,
    chartResult,
    symbols,
    units,
    palette,
  };
};

export default useAutoFmbAnalysisChart;

import { useMemo } from "react";

import { FossilyticsChartLine, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { CaseResult, FmbPiChartAnalysis } from "@/models/autoFmb";

import dictionary from "@/constants/dictionary";

import { caseKey } from "../../../constants";
import { generateLineSeries } from "../utils";
import { FluidType } from "@/models/Generic";

export type usePiChartsProps = {
  piChart?: CaseResult<FmbPiChartAnalysis>;
  colorScheme: string[];
  palette: any;
  fluidType: FluidType;
};

const safeFmbDictionary: { [key: string]: string } = dictionary.fmb;

const usePiChart = ({ palette, colorScheme, piChart, fluidType }: usePiChartsProps) => {
  const piAxes = useMemo(() => {
    return {
      xAxis: {
        name: `${dictionary.fmb.cumulativeProduction} ${
          dictionary.suffix[fluidType === FluidType.oil ? "cumulativeProductionOil" : "cumulativeProduction"]
        }`,
        type: "value",
        color: palette.customColor.black,
        min: 0,
      },
      yAxis: { name: dictionary.fmb.productivityIndex, type: "value", color: palette.customColor.black, min: 0, nameGap: 40 },
    };
  }, [fluidType, palette.customColor.black]);

  const piLineSeries = useMemo(() => {
    let series: FossilyticsChartSeries[] = [];
    let lines: FossilyticsChartLine[] = [];

    if (piChart) {
      caseKey.forEach((key, index) => {
        const currentItem = piChart[key as keyof CaseResult<FmbPiChartAnalysis>];
        series.push({
          name: `${dictionary.fmb.productivityIndexLegend} ${safeFmbDictionary[key]}`,
          type: "scatter",
          defaultDisabled: key !== "mid",
          color: colorScheme[index],
          data: currentItem.cumulative_production.map((point, index) => [point, currentItem.productivity_index[index]]),
        });
      });

      const { generatedLines, generatedSeries } = generateLineSeries({
        caseObject: piChart,
        colorList: colorScheme,
        prefixName: "PI ",
      });

      lines = generatedLines;
      series = series.concat(generatedSeries);
    }

    return {
      series,
      lines,
    };
  }, [piChart, colorScheme]);

  return {
    piAxes,
    piLineSeries,
  };
};

export default usePiChart;

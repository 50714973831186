import { boolean, number, object, string, z } from "zod";
import { autoForecastEventScheme, SummaryCardScheme } from "../Generic";

export const forecastFmbStateScheme = object({
  economic_cutoff: number(),
  smart_fill: boolean(),
  forecast_events: autoForecastEventScheme,
});

export type ForecastFmbState = z.infer<typeof forecastFmbStateScheme>;

// response
const forecastFmbChartDataScheme = object({
  dates: string().date().array(),
  flowing_pressure: number().array(),
  dry_gas_rate: number().array(),
  dry_cumulative_gas: number().array(),
  recombined_gas_rate: number().array(),
  recombined_cumulative_gas: number().array(),
  reservoir_pressure: number().array(),
  pseudo_reservoir_pressure: number().array(),
  pseudo_flowing_pressure: number().array(),
  gas_formation_volume_factor: number().array(),
  gas_viscosity: number().array(),
  gas_compressibility: number().array(),
  z: number().array(),
});

const forecastFmbChartOilDataScheme = object({
  dates: string().date().array(),
  flowing_pressure: number().array(),
  oil_rate: number().array(),
  cumulative_oil: number().array(),
  average_pressure: number().array(),
  average_pseudo_pressure: number().array(),
  pseudo_flowing_pressure: number().array(),
  average_oil_formation_volume_factor: number().array(),
  average_oil_viscosity: number().array(),
  average_oil_compressibility: number().array(),
  average_oil_density: number().array(),
  average_total_compressibility: number().array(),
});

const forecastFmbChartScheme = object({
  data: forecastFmbChartDataScheme.or(forecastFmbChartOilDataScheme),
  summary_card: SummaryCardScheme.array(),
});

export const forecastFmbFieldDataScheme = object({
  dates: string().date().array(),
  flowing_pressure: number().array(),
  dry_gas_rate: number().array(),
  dry_cumulative_gas: number().array(),
  recombined_gas_rate: number().array(),
  recombined_cumulative_gas: number().array(),
});

export type ForecastFmbFieldDataGas = z.infer<typeof forecastFmbFieldDataScheme>;

const forecastFmbFieldOilDataScheme = object({
  dates: string().date().array(),
  flowing_pressure: number().array(),
  oil_rate: number().array(),
  cumulative_oil: number().array(),
});

export type ForecastFmbFieldDataOil = z.infer<typeof forecastFmbFieldOilDataScheme>;

const forecastFieldDataScheme = forecastFmbFieldDataScheme.or(forecastFmbFieldOilDataScheme);

export const forecastFmbResultScheme = object({
  low: forecastFmbChartScheme,
  mid: forecastFmbChartScheme,
  high: forecastFmbChartScheme,
});

export const forecastFmbResultApi = object({
  field_data: forecastFieldDataScheme,
}).merge(forecastFmbResultScheme);

export type ForecastFmbResult = z.infer<typeof forecastFmbResultScheme>;

export type ForecastFmbFieldDataScheme = z.infer<typeof forecastFieldDataScheme>;

export const forecastFmbResultResponseScheme = object({
  forecast_inputs: forecastFmbStateScheme,
  forecast_result: forecastFmbResultApi,
});

export type ForecastCalculationState = {
  field_data: ForecastFmbFieldDataScheme;
  forecast_result: ForecastFmbResult;
};

export type ForecastFmbResultResponse = z.infer<typeof forecastFmbResultResponseScheme>;

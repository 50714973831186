import FossilyticsChart from "@/components/FossilyticsChart";

import { useFmbChart, useFmbChartsProps } from "../../../hooks/analysis";
import { BasePropsChart } from "../../../hooks/useAutoFmbAnalysis";

type FmbChartProps = useFmbChartsProps & BasePropsChart;

const FmbChart = ({ isLoading, onDragChartHandlebar, ...rest }: FmbChartProps) => {
  const { fmbChartAxes, fmbChartLineSeries } = useFmbChart(rest);

  return (
    <div
      style={{
        height: 600,
      }}
    >
      <FossilyticsChart
        id={"fmb_chart"}
        key={"fmb_chart"}
        isLoading={isLoading}
        xAxes={[fmbChartAxes.xAxis]}
        yAxes={[fmbChartAxes.yAxis]}
        series={fmbChartLineSeries.series}
        lines={fmbChartLineSeries.lines}
        onLineChange={onDragChartHandlebar}
      />
    </div>
  );
};

export default FmbChart;

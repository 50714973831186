import { useState, useMemo, useCallback } from "react";
import Tree from "rc-tree";
import styled from "@emotion/styled";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import dictionary from "@/constants/dictionary";
import { PaddingContainer } from "./DndProjectTree/style";
import { TransferProject } from "@/model";
import useThemeStyling from "@/utils/useThemeStyling";
import { DialogEnum } from "../types";

type PendingProjectItemProps = {
  setExpandedKeys: (val: string[]) => void;
  expandedKeys: string[];
  title: string;
  type: string;
  id: string;
  onClickProject: () => void;
};

const StyledTitleContainer = styled.div`
  height: 40px;
  align-items: center;
  display: flex;
  &:hover {
    background-color: rgb(243, 242, 241);
    cursor: pointer;
  }
`;

const PendingProjectItem = ({ setExpandedKeys, expandedKeys, title, type, id, onClickProject }: PendingProjectItemProps) => {
  const { palette } = useThemeStyling();

  const onClickItem = useCallback(() => {
    if (type === "project") onClickProject();
    else {
      const newExpandedKeys = [...expandedKeys];
      const currentKeyIndex = expandedKeys.indexOf(id);
      if (currentKeyIndex === -1) {
        newExpandedKeys.push(id);
      } else {
        newExpandedKeys.splice(currentKeyIndex, 1);
      }
      setExpandedKeys(newExpandedKeys);
    }
  }, [expandedKeys, id, onClickProject, setExpandedKeys, type]);

  const renderIcon = useCallback(() => {
    if (type === "project") return <div />;
    return <div>{expandedKeys.length > 0 ? <ExpandMoreIcon color={"primary"} /> : <ChevronRightIcon color={"primary"} />}</div>;
  }, [expandedKeys, type]);

  const titleStyle = {
    paddingLeft: 10,
    color: palette.primary.main,
    fontWeight: "bold",
  };

  return (
    <StyledTitleContainer>
      <PaddingContainer
        key={id}
        level={type === "project" ? 2 : 1}
        className="project-name"
        style={{
          paddingRight: 27,
          width: "100%",
        }}
      >
        <div
          onClick={onClickItem}
          onKeyDown={(event) => {
            if (event.key === "Enter") onClickItem();
          }}
          style={{ display: "flex", width: "100%", justifyContent: "space-between" }}
        >
          {renderIcon()}
          <div
            style={{
              display: "flex",
              width: "100%",
              paddingLeft: 20,
              fontSize: 14,
              ...(type === "title" ? titleStyle : {}),
            }}
            className={`name-text ${type}-title-item`}
          >
            {title}
          </div>
          {type === "project" && <MoreHorizIcon fontSize="small" sx={{ color: palette.primary.main }} />}
        </div>
      </PaddingContainer>
    </StyledTitleContainer>
  );
};

type PendingProjectsProps = {
  pendingProjects: TransferProject[] | undefined;
  username: string;
  setSelectedPendingProjectList: React.Dispatch<React.SetStateAction<TransferProject[]>>;
  setActiveDialog: (val: DialogEnum | undefined) => void;
};

const mapPendingProject = (pendingProjects: TransferProject[] | undefined, username: string, type: "in" | "out") => {
  if (!pendingProjects) return [];
  return pendingProjects
    .filter((project) => (type === "out" ? project.original_user === username : project.target_user === username))
    .map((project) => {
      return {
        key: project.id,
        title: project.name,
        type: "project",
        id: project.id,
        data: project,
        isOut: type === "out",
      };
    });
};

const PendingProjects = ({ pendingProjects, username, setSelectedPendingProjectList, setActiveDialog }: PendingProjectsProps) => {
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const treeData = useMemo(() => {
    const transferredOutProject = mapPendingProject(pendingProjects, username, "out");
    const transferredInProject = mapPendingProject(pendingProjects, username, "in");
    const result = [];

    if (transferredOutProject?.length > 0) {
      result.push({
        key: dictionary.nav.transferredProjects,
        title: dictionary.nav.transferredProjects,
        type: "title",
        id: dictionary.nav.transferredProjects,
        // only show project that is sent out
        children: transferredOutProject,
      });
    }
    if (transferredInProject.length > 0) {
      result.push({
        key: dictionary.nav.receivedProjects,
        title: dictionary.nav.receivedProjects,
        type: "title",
        id: dictionary.nav.receivedProjects,
        // only show project that is sent in
        children: transferredInProject,
      });
    }
    return result;
  }, [pendingProjects, username]);

  const renderItem = useCallback(
    (item: any) => {
      return (
        <PendingProjectItem
          title={item.title}
          type={item.type}
          id={item.id}
          expandedKeys={expandedKeys}
          onClickProject={() => {
            setSelectedPendingProjectList((state) => {
              const currentItem = state.findIndex((pendingProject: TransferProject) => pendingProject.id === item.data.id);
              if (currentItem > -1) return [];
              return [item.data];
            });
            setActiveDialog(item.isOut ? DialogEnum.PENDING_PROJECT_OUT : DialogEnum.PENDING_PROJECT_IN);
          }}
          setExpandedKeys={setExpandedKeys}
        />
      );
    },
    [expandedKeys, setActiveDialog, setSelectedPendingProjectList]
  );

  return (
    <Tree
      expandedKeys={expandedKeys}
      virtual
      height={height - 220}
      itemHeight={40}
      // @ts-ignore
      treeData={treeData}
      titleRender={(item) => renderItem(item)}
    />
  );
};

export default PendingProjects;

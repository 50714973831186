import { ModuleId } from "@/model";

export enum ModuleSection {
  GAZ = "GAZ",
  GILDRO = "GILDRO",
  KOLDUN = "KOLDUN",
  SPAD = "SPAD",
  TAHK = "TAHK",
  ROLLUP = "ROLLUP",
  MASLO = "MASLO",
  RULON = "RULON",
  MACHINE_LEARNING = "TSAR",
}

export const moduleDictionary: { [key: string]: string } = {
  [ModuleId.GAZ_FMB]: "Gas autoFMB",
  [ModuleId.MASLO_AUTOFMB]: "Oil autoFMB",
  [ModuleId.GAZ_PZ]: "Static P/z",
  [ModuleId.GAZ_AUTORTA]: "Gas autoRTA",
  [ModuleId.SPAD_DECLINE_GAS]: "Gas Decline",
  [ModuleId.SPAD_DECLINE_OIL]: "Oil Decline",
  [ModuleId.SPAD_WOR]: "WOR",
  [ModuleId.TAHK_FORECAST_GAS]: "Gas Forecast",
  [ModuleId.TAHK_FORECAST_OIL]: "Oil Forecast",
  [ModuleId.TAHK_FORECAST_CSG]: "CSG Forecast",
  [ModuleId.KOLDUN_MCSIM_GAS]: "Gas Monte-Carlo",
  [ModuleId.KOLDUN_MCSIM_OIL]: "Oil Monte-Carlo",
  [ModuleId.KOLDUN_MCSIM_CSG]: "CSG Monte-Carlo",
  [ModuleId.GILDRO_HYDRATE]: "Hydrate",
  [ModuleId.ROLLUP]: "Forecast Export",
  [ModuleId.MASLO_AUTORTA]: "Oil autoRTA",
  [ModuleId.RULON]: "Group Run",
  [ModuleId.RULON_GAS]: "Gas Rollup",
  [ModuleId.RULON_OIL]: "Oil Rollup",
  // not yet develop
  [ModuleId.GILDRO_PVT]: "PVT",
  [ModuleId.MACHINE_LEARNING]: "ML / Forecast",
};

// sorted module list for navbar
export const moduleMapping: { [key: string]: ModuleId[] } = {
  [ModuleSection.GAZ]: [ModuleId.GAZ_FMB, ModuleId.GAZ_PZ, ModuleId.GAZ_AUTORTA],
  [ModuleSection.GILDRO]: [ModuleId.GILDRO_PVT, ModuleId.GILDRO_HYDRATE],
  [ModuleSection.KOLDUN]: [ModuleId.KOLDUN_MCSIM_GAS, ModuleId.KOLDUN_MCSIM_OIL, ModuleId.KOLDUN_MCSIM_CSG],
  [ModuleSection.SPAD]: [ModuleId.SPAD_DECLINE_GAS, ModuleId.SPAD_DECLINE_OIL, ModuleId.SPAD_WOR],
  [ModuleSection.TAHK]: [ModuleId.TAHK_FORECAST_GAS, ModuleId.TAHK_FORECAST_OIL, ModuleId.TAHK_FORECAST_CSG],
  [ModuleSection.MASLO]: [ModuleId.MASLO_AUTORTA, ModuleId.MASLO_AUTOFMB],
  [ModuleSection.MACHINE_LEARNING]: [ModuleId.MACHINE_LEARNING],
};

// need to sort section for module grid
export const moduleGridSortedSectionSingle = [
  [ModuleSection.SPAD],
  [ModuleSection.GAZ, ModuleSection.MASLO],
  [ModuleSection.TAHK, ModuleSection.KOLDUN],
  [ModuleSection.GILDRO, ModuleSection.MACHINE_LEARNING],
];

export const moduleMappingMulti: { [key: string]: ModuleId[] } = {
  [ModuleSection.SPAD]: [ModuleId.SPAD_DECLINE_GAS, ModuleId.SPAD_DECLINE_OIL],
  [ModuleSection.ROLLUP]: [ModuleId.ROLLUP],
  [ModuleSection.RULON]: [ModuleId.RULON_GAS, ModuleId.RULON_OIL, ModuleId.RULON],
  [ModuleSection.MACHINE_LEARNING]: [ModuleId.MACHINE_LEARNING],
};

export const moduleGridSortedSectionMulti = [[ModuleSection.SPAD], [ModuleSection.RULON], [ModuleSection.MACHINE_LEARNING]];

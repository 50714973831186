import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";

import { Dialog, Alert, DialogContent, DialogTitle } from "@mui/material";

import { shallow } from "zustand/shallow";
import { useAppStore } from "@/features/app";

import helpLinkUrl from "@/constants/helpLinkUrl";
import dictionary from "@/constants/dictionary";

const StyledLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  color: unset;
`;

const ApiErrorMessageBar = () => {
  const { getBuildNumberText, apiError } = useAppStore(
    (state) => ({
      getBuildNumberText: state.getBuildNumberText,
      apiError: state.apiError,
    }),
    shallow
  );
  const [expiredLicense, setExpiredLicense] = useState(true);

  const buildNumberText = getBuildNumberText();

  const errorMapping: { [key: number]: string } = {
    409: `A conflict (HTTP 409 error) has occurred. Please refresh your browser. If the issue persists, contact support@predico.com.au`,
    // exclude error code below because we have different handling ( input error message )
    422: "",
  };

  const defaultError = `${
    apiError?.code !== 0 ? `${apiError?.code}, ` : ""
  } Please notify version ${buildNumberText} to support@predico.com.au for further assistance`;

  // expired license modal
  const isExpiredLicense = useMemo(() => {
    return apiError?.code === 403 && (apiError?.message ?? "") === dictionary.license.expiredLicenseError;
  }, [apiError?.code, apiError?.message]);

  if (!apiError || (errorMapping[Number(apiError?.code)] === "" && !apiError?.message)) return <></>;

  // !IMPORTANT!
  // this error message need to be dynamic since not all api error give the correct message,
  // that's why there's a lot of conditional for future prooofing but still support our legacy logic
  // get message from BE first
  // if none get fallback message from FE error mapping
  // if code for that error mapping doesn't exists display default
  return (
    <>
      <Dialog open={expiredLicense && isExpiredLicense} onClose={() => setExpiredLicense(false)}>
        <DialogTitle className="primaryColor" style={{ textAlign: "left" }}>
          <h4 className="noMarginVer">{dictionary.license.licenseExpired}</h4>
        </DialogTitle>
        <DialogContent style={{ width: 350 }}>
          Demo License has expired, please contact:
          <br />
          <a className="primaryColor" href={`mailto:support@predico.com.au?subject=Demo license expired`}>
            support@predico.com.au
          </a>
        </DialogContent>
      </Dialog>
      <Alert severity={apiError?.severity ?? "error"}>
        {apiError?.code ? (
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href={`${helpLinkUrl.errorCode}${apiError?.code && apiError?.code !== 0 ? apiError?.code : ""}`}
          >
            {apiError?.message ? `${apiError?.code} ${apiError?.message}` : errorMapping[Number(apiError?.code)] ?? defaultError}
          </StyledLink>
        ) : (
          <span>{apiError?.message ? apiError?.message : defaultError}</span>
        )}
      </Alert>
    </>
  );
};

export default ApiErrorMessageBar;

import FossilyticsChart from "@/components/FossilyticsChart";

import { usePiChart, usePiChartsProps } from "../../../hooks/analysis";
import { BasePropsChart } from "../../../hooks/useAutoFmbAnalysis";

type PiChartProps = usePiChartsProps & BasePropsChart;

const PiChart = ({ isLoading, onDragChartHandlebar, ...rest }: PiChartProps) => {
  const { piAxes, piLineSeries } = usePiChart(rest);

  return (
    <div
      style={{
        height: 600,
      }}
    >
      <FossilyticsChart
        id={"pi_chart"}
        key={"pi_chart"}
        isLoading={isLoading}
        xAxes={[piAxes.xAxis]}
        yAxes={[piAxes.yAxis]}
        series={piLineSeries.series}
        lines={piLineSeries.lines}
        onLineChange={onDragChartHandlebar}
      />
    </div>
  );
};

export default PiChart;

import AnalysisParameter from "./AnalysisParameter";
import AnalysisChartGas from "./AnalysisChartGas";
import AnalysisChartOil from "./AnalysisChartOil";

import { useAutoFmbState } from "../../context/AutoFmbContext";
import useAutoFmbAnalysis from "../../hooks/useAutoFmbAnalysis";
import { FluidType } from "@/models/Generic";

const AutoFmbAnalysis = () => {
  const {
    isLoading,
    apiError,
    autoFmbState,
    setAutoFmbState,
    analysisIdentity,
    tabIndex,
    setApiError,
    setIsLoading,
    setPollStatus,
    setProgress,
    setAutoFmbAnalysisCalculation,
    autoFmbAnalysisCalculation,
    setAutoFmbForecastCalculation,
    setUpdateInputFromAnalysis,
    expandParameter,
    fluidModuleType,
  } = useAutoFmbState();

  const {
    updateAnalysisFmb,
    onCalculateAnalysisFmb,
    summaryTableData,
    onTableCellChange,
    parameterTableRow,
    onPointTableCellChange,
    pzPointTableRow,
    onDragChartHandlebar,
  } = useAutoFmbAnalysis({
    setUpdateInputFromAnalysis,
    setApiError,
    setAutoFmbState,
    tabIndex,
    analysisIdentity,
    apiError,
    autoFmbState,
    setIsLoading,
    setPollStatus,
    setProgress,
    setAutoFmbAnalysisCalculation,
    autoFmbAnalysisCalculation,
    setAutoFmbForecastCalculation,
    fluidModuleType,
  });

  const ChartComponent = fluidModuleType === FluidType.gas ? AnalysisChartGas : AnalysisChartOil;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: expandParameter ? "480px 1fr" : "1fr",
        gridGap: 20,
        padding: "1em",
      }}
    >
      <div
        style={{
          display: expandParameter ? "unset" : "none",
        }}
      >
        <AnalysisParameter
          parameters={autoFmbState?.analysis}
          isLoading={isLoading}
          onChangeParameter={updateAnalysisFmb}
          onClickCalculate={onCalculateAnalysisFmb}
          summaryTableData={summaryTableData}
          onTableCellChange={onTableCellChange}
          parameterTableRow={parameterTableRow}
          onPointTableCellChange={onPointTableCellChange}
          pzPointTableRow={pzPointTableRow}
          fluidType={fluidModuleType}
        />
      </div>
      <ChartComponent
        autoFmbState={autoFmbState}
        onDragChartHandlebar={onDragChartHandlebar}
        analysisResult={autoFmbAnalysisCalculation}
        isLoading={isLoading}
        tabIndex={tabIndex}
        fluidType={fluidModuleType}
      />
      ;
    </div>
  );
};

export default AutoFmbAnalysis;

import { CaseResult, FmbChartAnalysis } from "@/models/autoFmb";

import { FossilyticsChartLine, FossilyticsChartLineConstraint, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import dictionary from "@/constants/dictionary";
import { caseKey } from "../../constants";
import { capitalizeFirstLetter } from "@/utils/general";

const safeFmbDictionary: { [key: string]: string } = dictionary.fmb;

export const generateLineSeries = ({
  caseObject,
  colorList,
  prefixName = "",
}: {
  caseObject: CaseResult<any>;
  colorList: string[];
  prefixName?: string;
}) => {
  const generatedSeries: FossilyticsChartSeries[] = [];
  const generatedLines: FossilyticsChartLine[] = [];

  caseKey.forEach((key, index) => {
    const handle = caseObject[key as keyof CaseResult<FmbChartAnalysis>].handle_bar;

    // Ensure handle contains the required properties
    if (!handle.identity || !handle.coordinates?.[0] || !handle.coordinates?.[1]) {
      return;
    }

    const nameKey = `fmb${capitalizeFirstLetter(key)}Case`;
    const name = `${prefixName}${safeFmbDictionary[nameKey]}`;

    // this is for the legend display
    generatedSeries.push({
      name,
      type: "line",
      color: colorList[index],
      data: [],
    });

    const draggableHead: FossilyticsChartLine = {
      id: handle.identity,
      name,
      linked: name,
      color: colorList[index],
      handleColor: colorList[index],
      line: [
        [handle.coordinates[0].x, handle.coordinates[0].y],
        [handle.coordinates[1].x, handle.coordinates[1].y],
      ],
      yMin: 0,
      xMin: 0,
    };

    if (handle.identity.includes("productivity_index_chart")) {
      draggableHead.xConstraint = FossilyticsChartLineConstraint.FIXED_VALUE;
      draggableHead.xConstraintValue = [handle.coordinates[0].x, handle.coordinates[1].x];
    } else {
      draggableHead.xConstraint = FossilyticsChartLineConstraint.FIXED_VALUE;
      draggableHead.xConstraintValue = [0, undefined];

      draggableHead.yConstraint = FossilyticsChartLineConstraint.FIXED_VALUE;
      draggableHead.yConstraintValue = [undefined, 0];
    }
    generatedLines.push(draggableHead);
  });

  return {
    generatedSeries,
    generatedLines,
  };
};

import { shallow } from "zustand/shallow";

import dictionary from "@/constants/dictionary";
import { useAppStore } from "@/features/app";
import ModulePage from "@/modules/ModulePage";
import { ModuleId } from "@/model";

import DataviewPage from "@/features/modules/dataview";
import AutoFmbProvider, { useAutoFmbState } from "./context/AutoFmbContext";
import AutoFmbInput from "./components/Input/AutoFmbInput";
import AutoFmbAnalysis from "./components/analysis/AutoFmbAnalysis";
import AutoFmbForecast from "./components/forecast/AutoFmbForecast";

import useExpandParameter from "@/utils/useExpandParameter";

const ModuleAutoFmb = ({ currentModule = ModuleId.GAZ_FMB }: { currentModule?: ModuleId }) => {
  const { isLoading, tabIndex, setTabIndex } = useAutoFmbState();

  return (
    <ModulePage
      title={dictionary.moduleName[currentModule]}
      tabIndex={tabIndex}
      onTabChange={(index) => {
        setTabIndex(index);
      }}
      tabs={[
        {
          headerText: dictionary.dataView.title,
          itemIcon: "ScatterChart",
          disabled: isLoading,
          content: <DataviewPage />,
          canSaveAsImg: true,
        },
        {
          headerText: "Input",
          itemIcon: "InputField",
          disabled: isLoading,
          content: <AutoFmbInput />,
          canSaveAsImg: true,
        },
        {
          headerText: "Analysis",
          itemIcon: "LineChart",
          disabled: isLoading,
          content: <AutoFmbAnalysis />,
          canSaveAsImg: true,
        },
        {
          headerText: "Forecast",
          itemIcon: "LineChart",
          disabled: isLoading,
          content: <AutoFmbForecast />,
          canSaveAsImg: true,
        },
      ]}
    />
  );
};

const AutoFmbWrapper = () => {
  const { selectedDataSets, setPollStatus, setProgress, setApiError, project, isLoading, apiError, setIsLoading, currentModule } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      setApiError: state.setApiError,
      isLoading: state.isLoading,
      project: state.project,
      apiError: state.apiError,
      setIsLoading: state.setIsLoading,
      setPollStatus: state.setPollStatus,
      setProgress: state.setProgress,
      currentModule: state.currentModule,
    }),
    shallow
  );

  const { expandParameter } = useExpandParameter();

  return (
    <AutoFmbProvider
      setPollStatus={setPollStatus}
      setProgress={setProgress}
      setIsLoading={setIsLoading}
      apiError={apiError}
      project={project}
      isLoading={isLoading}
      setApiError={setApiError}
      selectedDataSets={selectedDataSets}
      expandParameter={expandParameter}
      currentModule={currentModule}
    >
      <ModuleAutoFmb currentModule={currentModule} />
    </AutoFmbProvider>
  );
};
export default AutoFmbWrapper;

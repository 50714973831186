import { DropdownOption } from "@/components/fields/DropdownField";

export const convertCamelCaseToReadable = (text: string) => String(text).replace(/([a-z])([A-Z])/g, "$1 $2");

export const mapEnumToDropdown = (enumType: any) => {
  const res: DropdownOption[] = [];

  Object.entries(enumType).forEach(([key, value]) => {
    res.push({
      key,
      // split camel case with space
      text: String(value).replace(/([a-z])([A-Z])/g, "$1 $2"),
    });
  });
  return res;
};

export const mapStringArrayToDropdown = (list?: string[]) => {
  if (!list) return undefined;
  const res: DropdownOption[] = [];

  list.forEach((key) => {
    res.push({
      key,
      // split camel case with space
      text: String(key).replace(/([a-z])([A-Z])/g, "$1 $2"),
    });
  });
  return res;
};

export const mapEnumToDropdownReactGrid = (enumType: any) => {
  const res: {
    value: string;
    label: string;
  }[] = [];

  Object.entries(enumType).forEach(([key, value]) => {
    res.push({
      value: key,
      // split camel case with space
      label: String(value).replace(/([a-z])([A-Z])/g, "$1 $2"),
    });
  });
  return res;
};
export const mapArrayToDropdownReactGrid = (array: string[]) => {
  const res: {
    value: string;
    label: string;
  }[] = [];

  array.forEach((key) => {
    res.push({
      value: key,
      label: String(key),
    });
  });
  return res;
};

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
export const snakeToCamelCase = (str: string): string =>
  str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");

export const snakeToCamelFirstCaseLower = (snake: string) => snake.replace(/(_\w)/g, (match) => match[1].toUpperCase());

export const formatTestTitle = (title: string) => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9 ]/g, "")
    .replace(/\s+/g, "-");
};

export const formatMoney = (val: number | string) => {
  return val.toLocaleString("en-US", {
    maximumFractionDigits: 0,
  });
};

export const formatToEngineeringNotation = (val: number | string) => {
  if (String(val) === "undefined") return "";
  if (isNaN(Number(val)) || !String(val).trim()) return String(val);
  if (Number(val) === 0) return "0";

  const absValue = Math.abs(Number(val));

  // Handle small values below 0.001
  if (absValue < 0.001) {
    const exponent = Math.floor(Math.log10(absValue) / 3) * 3;
    const mantissa = absValue / Math.pow(10, exponent);
    const sign = exponent >= 0 ? "+" : "-";
    const formattedExponent = `${sign}${Math.abs(exponent).toString().padStart(2, "0")}`;
    return `${mantissa.toFixed(2)}E${formattedExponent}`;
  }

  // Handle fixed-point notation for values in range [0.001, 1000)
  if (absValue >= 0.001 && absValue < 1000) {
    return Number(val).toFixed(2);
  }

  // Handle values greater than or equal to 1000
  const exponent = Math.floor(Math.log10(absValue) / 3) * 3;
  const mantissa = absValue / Math.pow(10, exponent);
  const sign = exponent >= 0 ? "+" : "-";
  const formattedExponent = `${sign}${Math.abs(exponent).toString().padStart(2, "0")}`;
  return `${mantissa.toFixed(2)}E${formattedExponent}`;
};
export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getRandomColor = () => {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const isDevelopment =
  window.location.href.includes("dev") || window.location.href.includes("uat") || window.location.href.includes("localhost");

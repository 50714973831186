import { useMemo } from "react";

import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import dictionary from "@/constants/dictionary";
import { Row } from "@silevis/reactgrid";

import { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";
import { capitalizeFirstLetter, formatToEngineeringNotation } from "@/utils/general";
import { FluidType } from "@/models/Generic";

import {
  forecastGasDataTableHeader,
  forecastOilDataTableHeader,
  forecastGasDataTableNotation,
  forecastOilDataTableNotation,
  fieldGasDataTableHeader,
  fieldGasDataTableNotation,
  fieldOilDataTableHeader,
  fieldOilDataTableNotation,
} from "../../constants";
import useThemeStyling from "@/utils/useThemeStyling";

import { AutoRtaFieldData, AutoRtaForecastCalculationV2 } from "@/models/gaz/autoRta";

export type UseAutoRtaForecastResultProps = {
  fluidType: FluidType;
  autoRtaForecastCalculation?: AutoRtaForecastCalculationV2;
  autoRtaFieldData?: AutoRtaFieldData;
};

const getDataTableCols = (headers: string[]) => {
  return headers.map((header) => ({
    columnId: header,
  }));
};

const getDataTableRows = (data: any, headers: string[], notations: string[]) => {
  if (!data?.dates) return [];
  return [
    {
      rowId: "header",
      height: 60,
      cells: headers.map((header) => ({
        type: "header",
        text: capitalizeFirstLetter(header.replaceAll("_", " ")),
        style: tableHeaderStyle,
      })),
    },
    {
      rowId: "notation",
      cells: notations.map((notation) => ({
        type: "header",
        style: tableCellStyle,
        text: notation,
      })),
    },
    ...data.dates.map((_: any, index: number) => ({
      key: index,
      rowId: index + 1,
      cells: headers.map((header) => {
        const result: { [key: string]: any } = {
          type: header === "dates" ? "date" : "text",
          nonEditable: true,
          style: tableCellStyle,
        };
        const text = data[header]?.[index];
        if (header === "dates") {
          result.date = new Date(text);
          result.format = Intl.DateTimeFormat();
        } else {
          result.text = formatToEngineeringNotation(text);
        }
        return result;
      }),
    })),
  ] as Row[];
};

const useAutoRtaForecastResult = ({ fluidType, autoRtaForecastCalculation, autoRtaFieldData }: UseAutoRtaForecastResultProps) => {
  const { palette } = useThemeStyling();

  const seriesAxisColor = useMemo(() => {
    if (fluidType === FluidType.gas) return palette.customColor.red;
    return palette.customColor.green;
  }, [fluidType, palette.customColor.green, palette.customColor.red]);

  const forecastCalculationDataTableCol = useMemo(() => {
    const headerList = fluidType === FluidType.gas ? forecastGasDataTableHeader : forecastOilDataTableHeader;

    return getDataTableCols(headerList);
  }, [fluidType]);

  const forecastCalculationDataTableRow = useMemo(() => {
    if (!autoRtaForecastCalculation?.data) return [];
    const headerList = fluidType === FluidType.gas ? forecastGasDataTableHeader : forecastOilDataTableHeader;
    const notationList = fluidType === FluidType.gas ? forecastGasDataTableNotation : forecastOilDataTableNotation;

    return getDataTableRows(autoRtaForecastCalculation.data, headerList, notationList);
  }, [autoRtaForecastCalculation?.data, fluidType]);

  const fieldDataRow = useMemo(() => {
    if (!autoRtaFieldData) return [];
    const headerList = fluidType === FluidType.gas ? fieldGasDataTableHeader : fieldOilDataTableHeader;
    const notationList = fluidType === FluidType.gas ? fieldGasDataTableNotation : fieldOilDataTableNotation;

    return getDataTableRows(autoRtaFieldData, headerList, notationList);
  }, [autoRtaFieldData, fluidType]);

  const fieldDataCol = useMemo(() => {
    const headerList = fluidType === FluidType.gas ? fieldGasDataTableHeader : fieldOilDataTableHeader;
    return getDataTableCols(headerList);
  }, [fluidType]);

  const forecastXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "Date", type: "time", color: palette.customColor.black }],
    [palette.customColor.black]
  );

  const forecastYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      { name: dictionary.autoRta[`${fluidType}Rate`], type: "value", color: seriesAxisColor },
      { name: dictionary.autoRta.pressure, type: "value", color: palette.customColor.black },
    ],
    [fluidType, palette.customColor.black, seriesAxisColor]
  );

  const forecastCalcSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (autoRtaForecastCalculation?.data && autoRtaFieldData?.dates) {
      const safeData: { [key: string]: any } = autoRtaForecastCalculation?.data;
      const safeFieldData: { [key: string]: any } = autoRtaFieldData;

      const pressure = {
        name: dictionary.gas.pressure,
        type: "line",
        color: palette.customColor.black,
        yAxisIndex: 1,
        data: safeData.dates.map((x: string, i: number) => [x, safeData.flowing_pressure?.[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 2,
      };

      const pressureMeasured = {
        name: `${dictionary.gas.pressureMeasured}`,
        type: "scatter",
        color: palette.customColor.black,
        yAxisIndex: 1,
        data: safeFieldData.dates.map((x: string, i: number) => [x, safeFieldData[`flowing_pressure`]?.[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 2,
      };

      if (fluidType === FluidType.gas) {
        const averagePressure = {
          name: `${dictionary.autoRta.averagePressure}`,
          type: "line",
          color: palette.customColor.black,
          yAxisIndex: 1,
          data: safeData.dates.map((x: string, i: number) => [x, safeData[`average_pressure`]?.[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 2,
        };
        return [
          {
            name: `${dictionary.autoRta.modelled} ${dictionary.autoRta.gasRate}`,
            type: "line",
            color: palette.customColor.red,
            yAxisIndex: 0,
            data: safeData.dates.map((x: string, i: number) => [x, safeData[`dry_gas_rate`]?.[i]]) ?? [],
            hideSymbol: true,
            lineWidth: 2,
          },
          {
            name: `${dictionary.autoRta.modelled} ${dictionary.autoRta.recombinedGasRate}`,
            type: "line",
            color: palette.customColor.red,
            yAxisIndex: 0,
            data: safeData.dates.map((x: string, i: number) => [x, safeData[`recombined_gas_rate`]?.[i]]) ?? [],
            hideSymbol: true,
            lineWidth: 2,
          },
          {
            name: `${dictionary.autoRta.measured} ${dictionary.autoRta.gasRate}`,
            type: "scatter",
            color: palette.customColor.red,
            yAxisIndex: 0,
            data: autoRtaFieldData.dates.map((x: string, i: number) => [x, safeFieldData.dry_gas_rate?.[i]]) ?? [],
            hideSymbol: true,
            lineWidth: 2,
          },
          pressure,
          pressureMeasured,
          averagePressure,
        ];
      }
      return [
        {
          name: `${dictionary.autoRta.modelled} ${dictionary.autoRta.oilRate}`,
          type: "line",
          color: palette.customColor.green,
          yAxisIndex: 0,
          data: safeData.dates.map((x: string, i: number) => [x, safeData[`oil_rate`]?.[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 2,
        },
        {
          name: `${dictionary.autoRta.measured} ${dictionary.autoRta.oilRate}`,
          type: "scatter",
          color: palette.customColor.green,
          yAxisIndex: 0,
          data: safeFieldData.dates.map((x: string, i: number) => [x, safeFieldData[`oil_rate`]?.[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 2,
        },
        pressure,
        pressureMeasured,
      ];
    }
    return [];
  }, [autoRtaFieldData, autoRtaForecastCalculation?.data, fluidType, palette.customColor.black, palette.customColor.green, palette.customColor.red]);

  const cumulativeGasChartSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!autoRtaForecastCalculation?.data || !autoRtaFieldData?.dates) return [];
    const safeData: { [key: string]: any } = autoRtaForecastCalculation.data;
    const safeFieldData: { [key: string]: any } = autoRtaFieldData;

    if (fluidType === FluidType.oil) {
      return [
        {
          name: `${dictionary.autoRta.modelled} ${dictionary.autoRta.oilCumulative}`,
          type: "line",
          color: palette.customColor.green,
          yAxisIndex: 0,
          data: safeData.dates.map((x: string, i: number) => [x, safeData[`cumulative_${fluidType}`]?.[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 3,
        },
        {
          name: `${dictionary.autoRta.measured} ${dictionary.autoRta.oilCumulative}`,
          type: "scatter",
          color: palette.customColor.green,
          yAxisIndex: 0,
          data: safeFieldData.dates.map((x: string, i: number) => [x, safeFieldData[`cumulative_${fluidType}`]?.[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 3,
        },
      ];
    }

    return [
      {
        name: `${dictionary.autoRta.modelled} ${dictionary.autoRta.gasCumulative}`,
        type: "line",
        color: palette.customColor.red,
        yAxisIndex: 0,
        data: safeData.dates.map((x: string, i: number) => [x, safeData.dry_cumulative_gas?.[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 3,
      },
      {
        name: `${dictionary.autoRta.measured} ${dictionary.autoRta.gasCumulative}`,
        type: "scatter",
        color: palette.customColor.red,
        yAxisIndex: 0,
        data: safeFieldData.dates.map((x: string, i: number) => [x, safeFieldData.dry_cumulative_gas?.[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 3,
      },
      {
        name: `${dictionary.autoRta.modelled} ${dictionary.autoRta.recombinedGasCumulative}`,
        type: "line",
        color: palette.customColor.red,
        yAxisIndex: 0,
        data: safeData.dates.map((x: string, i: number) => [x, safeData.recombined_cumulative_gas?.[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 3,
      },
    ];
  }, [autoRtaFieldData, autoRtaForecastCalculation?.data, fluidType, palette.customColor.green, palette.customColor.red]);

  const cumulativeGasYAxes = useMemo<FossilyticsChartAxis[]>(() => {
    return [
      {
        name: (dictionary.genericChart as { [key: string]: string })[`cumulative${capitalizeFirstLetter(fluidType)}`],
        type: "value",
        color: seriesAxisColor,
        nameGap: 45,
      },
    ];
  }, [fluidType, seriesAxisColor]);

  return {
    forecastCalculationDataTableRow,
    forecastXAxes,
    forecastYAxes,
    forecastCalcSeries,
    cumulativeGasChartSeries,
    cumulativeGasYAxes,
    forecastCalculationDataTableCol,
    fieldDataRow,
    fieldDataCol,
  };
};

export default useAutoRtaForecastResult;

import { useMemo } from "react";

import { FossilyticsChartLine, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { CaseResult, FmbPzChartAnalysis, PZChart } from "@/models/autoFmb";

import dictionary from "@/constants/dictionary";

import { caseKey } from "../../../constants";
import { generateLineSeries } from "../utils";

export type usePzChartsProps = {
  pzChart?: PZChart;
  colorScheme: string[];
  palette: any;
};

const safeFmbDictionary: { [key: string]: string } = dictionary.fmb;

const usePzCharts = ({ pzChart, colorScheme, palette }: usePzChartsProps) => {
  const pzLineSeries = useMemo(() => {
    let series: FossilyticsChartSeries[] = [];
    let lines: FossilyticsChartLine[] = [];
    if (!pzChart)
      return {
        series,
        lines,
      };

    caseKey.forEach((key, index) => {
      const currentItem = pzChart?.[key as keyof CaseResult<FmbPzChartAnalysis>];
      if (currentItem) {
        series.push({
          name: `${dictionary.fmb.flowingPzStar} ${safeFmbDictionary[key]}`,
          type: "scatter",
          defaultDisabled: key !== "mid",
          color: colorScheme[index],
          data: currentItem.cumulative_production.map((point, index) => [point, currentItem.flowing_p_star_on_z_double_star[index]]),
        });
      }
    });

    const { generatedLines, generatedSeries } = generateLineSeries({
      caseObject: pzChart,
      colorList: colorScheme,
      prefixName: "P/Z ",
    });

    lines = generatedLines;
    series = series.concat(generatedSeries);
    if (pzChart.manual_p_on_z) {
      // add manual point
      series.push({
        name: `${dictionary.fmb.manualPoint}`,
        type: "scatter",
        defaultDisabled: false,
        color: palette.common.black,
        data: pzChart.manual_p_on_z?.p_on_z_chart_manually_add.map((point) => [point.x, point.y]),
      });

      // add manual line
      // legend
      series.push({
        name: dictionary.fmb.manualLine,
        type: "line",
        color: palette.common.black,
        defaultDisabled: true,
        data: [],
      });

      const line = pzChart.manual_p_on_z?.p_on_z_chart_manual_line;
      // the line itself
      lines.push({
        controllable: false,
        id: "line-id",
        name: dictionary.fmb.manualLine,
        linked: dictionary.fmb.manualLine,
        color: palette.common.black,
        handleColor: palette.common.black,
        line: [
          [line.x1, line.y1],
          [line.x2, line.y2],
        ],
      });
    }

    return {
      series,
      lines,
    };
  }, [pzChart, colorScheme, palette.common.black]);

  const pzAxes = useMemo(() => {
    return {
      xAxis: {
        name: `${dictionary.fmb.cumulativeProduction} ${dictionary.suffix.cumulativeProduction}`,
        type: "value",
        color: palette.customColor.black,
        min: 0,
      },
      yAxis: {
        name: dictionary.fmb.pzAxis,
        type: "value",
        color: palette.customColor.black,
        min: 0,
        nameGap: 40,
        offset: 0,
      },
    };
  }, [palette]);

  return { pzAxes, pzLineSeries };
};

export default usePzCharts;

import { createContext, useContext, useState, useMemo, useCallback, useEffect, ReactNode, useRef } from "react";
import { shallow } from "zustand/shallow";
import { treeViewDefaultState, AFA_WELL_TYPE, AFA_PROJECT_NAV_WIDTH, AFA_WELL_NAV_WIDTH } from "../constants";
import { Project, TransferProject } from "@/model";
import { DataSetsResponse, DataSetsResponseSchema, MissingDataSet } from "../../../models/wells/State";
import { SettingStateV2, getWellsSettings, SettingStateV2Schema } from "@/models/settings";
import { useAppStore } from "@/features/app";
import { useSettings } from "@/SettingsState";
import { IItem, TreeViewStateV2, Tags, SelectedTags, DialogEnum } from "../types";

// hooks
import useDialogSection from "./useDialogSectionV2";
import useWellFilter from "./useWellFilter";
import useProjectNav from "./useProjectNav";
import { getIdFromActiveKey, hasModifiedDefaultSelectionUtils, buildTagDictionary } from "../utils";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "@/utils/apiFetcher";
import { groupApi } from "@/constants/apiUrl";
import { parseErrorThrown } from "@/utils/errorHandling";

const TreeViewContext = createContext<TreeViewStateV2>(treeViewDefaultState);

type TreeViewContextProps = {
  children: ReactNode;
};

const TreeViewProvider = ({ children }: Readonly<TreeViewContextProps>) => {
  const {
    isLoading,
    projects,
    setProjects,
    setProject,
    setGroup,
    dataSets,
    setDataSets,
    setSelectedDataSets,
    setSelectedKey,
    selectedKey,
    user,
    group,
    currentModule,
    setApiError,
    apiError,
    setIsLoading,
    project,
  } = useAppStore(
    (state) => ({
      isLoading: state.isLoading,
      projects: state.projects,
      setProjects: state.setProjects,
      setProject: state.setProject,
      setGroup: state.setGroup,
      dataSets: state.dataSets,
      setDataSets: state.setDataSets,
      setSelectedDataSets: state.setSelectedDataSets,
      setSelectedKey: state.setSelectedKey,
      selectedKey: state.selectedKey,
      user: state.user,
      group: state.group,
      currentModule: state.currentModule,
      setApiError: state.setApiError,
      apiError: state.apiError,
      setIsLoading: state.setIsLoading,
      project: state.project,
    }),
    shallow
  );

  const { setSettings } = useSettings();

  const rootId = "tree-view-context";

  const [isDataSetsLoading, setIsDataSetsLoading] = useState(true);
  const [dataSetItems, setDataSetItems] = useState<IItem[]>([]);
  const [isProjectEmpty, setIsProjectEmpty] = useState(false);
  const [tags, setTags] = useState<Tags>({});
  const [selectedTags, setSelectedTags] = useState<SelectedTags>({});
  const [hasInitializedAfaWellType, setHasInitializedAfaWellType] = useState(false);

  const [pendingProjects, setPendingProjects] = useState<TransferProject[]>();

  const [missingDataset, setMissingDataset] = useState<MissingDataSet[]>([]);

  const [errorWarning, setErrorWarning] = useState<string | undefined>();
  const { clearFilters, filter, setFilter, filterButtonRef, showFilters, setShowFilters, filterTypes, setFilterTypes } = useWellFilter();

  const [activeDialog, setActiveDialog] = useState<DialogEnum | undefined>();
  const [selectedPendingProjectList, setSelectedPendingProjectList] = useState<TransferProject[]>([]);

  //For treeview width
  const [wellWidth, setWellWidth] = useState(AFA_WELL_NAV_WIDTH);
  const [navWidth, setNavWidth] = useState(AFA_PROJECT_NAV_WIDTH);

  const haveInitialize = useRef(false);

  const refreshDataSets = useCallback(
    async (delayStateChange: boolean = false) => {
      if (!user) return;

      setIsDataSetsLoading(true);
      try {
        const res = await fetchApi<DataSetsResponse[]>({ path: "/loaders/v2/datasets" });
        if (!res.data) return;

        const dataSetsResponse = DataSetsResponseSchema.parse(res.data);
        setDataSets(dataSetsResponse.datasets);
        setMissingDataset(dataSetsResponse?.missing_datasets);

        if (delayStateChange) return dataSetsResponse;
      } catch (error: any) {
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      } finally {
        setIsDataSetsLoading(false);
      }
    },
    [setDataSets, user, setApiError, apiError]
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasInitializedAfaWellType && tags[AFA_WELL_TYPE]) {
      setSelectedTags((prevSelectedTags) => ({
        ...prevSelectedTags,
        [AFA_WELL_TYPE]: Object.keys(tags[AFA_WELL_TYPE]),
      }));
      setHasInitializedAfaWellType(true);
    }
  }, [tags, hasInitializedAfaWellType, setSelectedTags]);

  // Load data sets and projects from API
  const refreshProjects = useCallback(
    async (delayStateChange: boolean = false) => {
      if (!user) return;
      try {
        const res = await fetchApi<{ projects: Project[]; transfer_projects: TransferProject[] }>({ path: "/projects" });
        if (!res.data) return;
        const projectsResponse: Project[] = res.data.projects;
        setPendingProjects(res.data.transfer_projects);
        const transferInProjects = res.data.transfer_projects.filter((project) => project.target_user === user.username);
        if (transferInProjects.length > 0) {
          setActiveDialog(DialogEnum.PENDING_PROJECT_IN);
          setSelectedPendingProjectList(transferInProjects);
        }
        setProjects(projectsResponse);
        if (delayStateChange) return projectsResponse;

        const response = await getWellsSettings();
        const saveProject: SettingStateV2 = {};

        //need to ensure get the data else throw error
        if (response.data) {
          const parsedData = SettingStateV2Schema.parse(response.data);

          Object.keys(parsedData).forEach((projectId) => {
            const currProject = parsedData[projectId];
            if (currProject) {
              saveProject[projectId] = {
                ...currProject,
                project_setting: currProject.project_setting,
              };
            }
          });
          setSettings(saveProject);
        }
      } catch (error) {
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    [user, setProjects, setActiveDialog, setSelectedPendingProjectList, setSettings, setApiError, apiError]
  );

  const {
    hideProjects,
    mappedItemKeyTotal,
    onDragEnd,
    setHideProjects,
    setShowAll,
    showAll,
    selectedItems,
    setActiveKey,
    activeKey,
    selectedDataSet,
    setSelectedDataSet,

    shiftHeld,
    commandControlHeld,

    selectedKeys,
    onSelectItem,
    onDragWellList,
    onDropWell,
  } = useProjectNav({
    projects,
    refreshProjects,
    setProject,
    setGroup,
    setSelectedDataSets,
    setSelectedKey,
    dataSets,
    selectedKey,
    navigate,
    setErrorWarning,
    currentModule,
  });

  const {
    copyFromSelected,
    setCopyFromSelected,
    createNewProject,
    editExistingProject,
    deleteProject,
    removeDataSets,
    createNewGroups,
    editExistingGroup,
    newGroupsNames,
    setNewGroupsNames,
    getTenantUserList,
    transferUserIdList,
    transferProject,
    finalizeTransferProject,
  } = useDialogSection({
    activeDialog,
    setActiveDialog,
    refreshProjects,
    activeKey,
    setActiveKey,
    setGroup,
    setApiError,
    setIsLoading,
    setProject,
    setSelectedDataSets,
    setSelectedKey,
    apiError,
  });

  const initializeData = useCallback(async () => {
    try {
      let newDataSets, newProjects;
      haveInitialize.current = true;

      if (!dataSets) newDataSets = await refreshDataSets(true);
      if (!projects) newProjects = await refreshProjects(true);

      // Update state at the same time to prevent rerender
      if (newDataSets) {
        setDataSets(newDataSets.datasets);
        setTags(newDataSets.tags);
      }

      if (newProjects) {
        setProjects(newProjects);
        setIsProjectEmpty(newProjects.length === 0);
      }
      setIsDataSetsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [dataSets, projects, refreshDataSets, refreshProjects, setDataSets, setProjects]);

  useEffect(() => {
    if (haveInitialize.current || !user) return;
    initializeData();
  }, [initializeData, user]);

  useEffect(() => {
    if (dataSets) {
      // Map dataSets to dataSetItems
      const newItems = dataSets.map((d) => ({ key: d.id, name: d.name, data: d }));
      setDataSetItems(newItems);

      // Build tags dictionary
      const tagsObj = buildTagDictionary(dataSets);
      setTags(tagsObj);
    }
  }, [dataSets]);

  const deleteGroup = useCallback(async () => {
    // Close the dialog
    setActiveDialog(undefined);

    // Get project and group ID
    if (!activeKey) return;
    const { projectId, groupIds } = getIdFromActiveKey(activeKey);

    const groupId = groupIds[groupIds.length - 1];

    try {
      setIsLoading(true);
      await fetchApi({
        path: groupApi(projectId, groupId),
        type: "del",
      });
      await refreshProjects();
    } catch (error: any) {
      console.error(error);
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
    } finally {
      setIsLoading(false);
    }
  }, [activeKey, apiError, refreshProjects, setActiveDialog, setApiError, setIsLoading]);

  const hasModifiedDefaultSelection = useMemo(() => hasModifiedDefaultSelectionUtils(selectedTags, tags), [selectedTags, tags]);

  const onClickPreview = useCallback(
    (dataSetId: string, isOutsideList: boolean) => {
      // isOutsideList means its is not in user project
      navigate(`/modules/dataPreview/${dataSetId}?type=${isOutsideList ? "isOutsideList" : ""}`);
    },
    [navigate]
  );

  // For treeview width
  const treeViewWidth = useMemo(() => {
    if (showAll) return navWidth + wellWidth + 10 + 80; //10 is the dragging line & 80 is the padding approximately
    if (!hideProjects) return navWidth + 10 + 120;
    return 70;
  }, [showAll, hideProjects, wellWidth, navWidth]);

  const state = useMemo(() => {
    return {
      isLoading,
      selectedKey,
      rootId,
      showAll,
      setShowAll,
      setHideProjects,

      setShowFilters,
      showFilters,
      filterButtonRef,
      filterTypes,
      setFilterTypes,
      clearFilters,
      filter,
      setFilter,
      isDataSetsLoading,
      dataSetItems,
      projects,
      onDragWellList,

      //tags
      selectedTags,
      setSelectedTags,
      tags,
      hasModifiedDefaultSelection,

      // project nav:
      hideProjects,
      mappedItemKeyTotal,
      onDragEnd,
      selectedItems,
      setActiveKey,
      isProjectEmpty,

      // dialog props
      copyFromSelected,
      setCopyFromSelected,

      createNewProject,
      deleteProject,
      editExistingProject,

      createNewGroups,
      editExistingGroup,
      setNewGroupsNames,
      newGroupsNames,
      refreshProjects,
      removeDataSets,

      activeDialog,
      setActiveDialog,
      selectedGroup: group,
      deleteGroup,
      selectedDataSet,
      setSelectedDataSet,
      refreshDataSets,
      dataSets,
      shiftHeld,
      commandControlHeld,
      errorWarning,

      selectedKeys,
      onSelectItem,
      onDropWell,
      onClickPreview,

      setWellWidth,
      setNavWidth,
      treeViewWidth,
      missingDataset,

      project,
      getTenantUserList,
      userIdList: transferUserIdList,
      transferProject,
      pendingProjects,
      username: user?.username,
      finalizeTransferProject,
      selectedPendingProjectList,
      setSelectedPendingProjectList,
    };
  }, [
    selectedPendingProjectList,
    setSelectedPendingProjectList,
    finalizeTransferProject,
    user?.username,
    pendingProjects,
    getTenantUserList,
    transferUserIdList,
    transferProject,
    project,
    setWellWidth,
    setNavWidth,
    treeViewWidth,
    onClickPreview,
    onDragWellList,
    onDropWell,
    group,
    isLoading,
    selectedKey,
    showAll,
    setShowAll,
    setHideProjects,
    setShowFilters,
    showFilters,
    filterButtonRef,
    filterTypes,
    setFilterTypes,
    clearFilters,
    filter,
    setFilter,
    isProjectEmpty,
    isDataSetsLoading,
    dataSetItems,
    projects,
    hideProjects,
    mappedItemKeyTotal,
    onDragEnd,
    selectedItems,
    copyFromSelected,
    setCopyFromSelected,
    createNewProject,
    deleteProject,
    editExistingProject,
    createNewGroups,
    editExistingGroup,
    setNewGroupsNames,
    newGroupsNames,
    refreshProjects,
    removeDataSets,
    activeDialog,
    setActiveDialog,
    setActiveKey,
    deleteGroup,
    selectedDataSet,
    setSelectedDataSet,
    refreshDataSets,
    dataSets,
    shiftHeld,
    commandControlHeld,
    errorWarning,
    selectedKeys,
    onSelectItem,

    //tags
    selectedTags,
    setSelectedTags,
    tags,
    hasModifiedDefaultSelection,

    missingDataset,
  ]);

  return <TreeViewContext.Provider value={state}>{children}</TreeViewContext.Provider>;
};

export function useTreeViewState() {
  return useContext(TreeViewContext);
}

export default TreeViewProvider;

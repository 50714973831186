import React, { useMemo } from "react";

import Tabs from "@/components/Tabs";
import dictionary from "@/constants/dictionary";
import { FmbInputState } from "@/models/autoFmb";
import { InputGridCalculation } from "@/models/InputGeneric";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

import { InputLangmuirIsotherm, InputOilPvt, WellboreInput, InputGasPvt, InputWaterPvt } from "@/features/shared";

import CustomCard from "@/components/Card";
import useThemeStyling from "@/utils/useThemeStyling";

type AutoFmbModelComponentsParamProps = {
  fmbInputState?: FmbInputState;
  calculateValue?: InputGridCalculation;
  isLoading: boolean;
  updateInputFmb: (val: any, key: string, objectKey: string) => void;
  validationError: ErrorValidationDetail[];
  flowingPressureOptions?: string[];
  oilFirst?: boolean;
};

const ModelComponents = ({
  fmbInputState,
  calculateValue,
  isLoading,
  updateInputFmb,
  validationError,
  flowingPressureOptions,
  oilFirst,
}: AutoFmbModelComponentsParamProps) => {
  const { palette } = useThemeStyling();

  const tabList = useMemo(() => {
    const res = [
      {
        label: <span>{dictionary.inputPage.gasPvt}</span>,
        key: dictionary.inputPage.gasPvt,
        content: (
          <InputGasPvt
            validationError={validationError}
            gasPvtCalculation={calculateValue?.gas_pvt_charts}
            gasPvtInput={fmbInputState?.gas_pvt}
            isLoading={isLoading}
            updateInputValue={updateInputFmb}
          />
        ),
      },
      {
        key: dictionary.inputPage.waterPvt,
        label: <span>{dictionary.inputPage.waterPvt}</span>,
        content: (
          <InputWaterPvt
            validationError={validationError}
            waterPvtCalculation={calculateValue?.water_pvt_charts}
            waterPvtInput={fmbInputState?.water_pvt}
            isLoading={isLoading}
            updateInputValue={updateInputFmb}
          />
        ),
      },

      {
        key: dictionary.inputPage.wellboreModel,
        label: <span>{dictionary.inputPage.wellboreModel}</span>,
        content: (
          <WellboreInput
            flowingPressureOptions={flowingPressureOptions}
            isLoading={isLoading}
            validationError={validationError}
            updateInputValue={updateInputFmb}
            wellboreInput={fmbInputState?.wellbore_model}
          />
        ),
      },
    ];

    if (fmbInputState?.langmuir_isotherm && calculateValue?.langmuir_charts) {
      res.splice(2, 0, {
        key: dictionary.inputPage.langmuirIsotherm,
        label: <span>{dictionary.inputPage.langmuirIsotherm}</span>,
        content: (
          <InputLangmuirIsotherm
            langmuirIsothermInput={fmbInputState?.langmuir_isotherm}
            langmuirIsothermCalculation={calculateValue?.langmuir_charts}
            isLoading={isLoading}
            updateInputValue={updateInputFmb}
            validationError={validationError}
          />
        ),
      });
    }
    const oilPvt = {
      key: dictionary.inputPage.oilPvt,
      label: <span>{dictionary.inputPage.oilPvt}</span>,
      content: (
        <InputOilPvt
          validationError={validationError}
          oilPvtInput={fmbInputState?.oil_pvt}
          oilPvtCalculation={calculateValue?.oil_pvt_charts}
          isLoading={isLoading}
          updateInputValue={updateInputFmb}
        />
      ),
    };
    if (oilFirst) {
      res.unshift(oilPvt);
    } else {
      res.splice(2, 0, oilPvt);
    }
    return res;
  }, [
    validationError,
    calculateValue?.gas_pvt_charts,
    calculateValue?.water_pvt_charts,
    calculateValue?.langmuir_charts,
    calculateValue?.oil_pvt_charts,
    fmbInputState?.gas_pvt,
    fmbInputState?.water_pvt,
    fmbInputState?.wellbore_model,
    fmbInputState?.langmuir_isotherm,
    fmbInputState?.oil_pvt,
    isLoading,
    updateInputFmb,
    flowingPressureOptions,
    oilFirst,
  ]);

  return (
    <CustomCard>
      <h3 style={{ color: palette.primary.main, margin: 0 }}>{dictionary.inputPage.modelComponents}</h3>
      <div
        style={{
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <Tabs tabList={tabList} centered />
      </div>
    </CustomCard>
  );
};

export default ModelComponents;

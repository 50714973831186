import { useMemo } from "react";

import { FossilyticsChartLine, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import dictionary from "@/constants/dictionary";

import { CaseResult, FmbChartAnalysis } from "@/models/autoFmb";

import { caseKey } from "../../../constants";
import { generateLineSeries } from "../utils";
import { FluidType } from "@/models/Generic";

export type useFmbChartsProps = {
  palette: any;
  fmbChart?: CaseResult<FmbChartAnalysis>;
  colorScheme: string[];
  fluidType?: FluidType;
};

const safeFmbDictionary: { [key: string]: string } = dictionary.fmb;

const useFmbCharts = ({ palette, fmbChart, colorScheme, fluidType }: useFmbChartsProps) => {
  const fmbChartAxes = useMemo(() => {
    return {
      xAxis: {
        name: dictionary.fmb[fluidType === FluidType.oil ? "normalizeCumulativeProductionOil" : "normalizeCumulativeProduction"],
        type: "value",
        color: palette.customColor.black,
        min: 0,
      },
      yAxis: {
        name: dictionary.fmb[fluidType === FluidType.oil ? "normalizeRateOil" : "normalizeRate"],
        type: "value",
        color: palette.customColor.black,
        min: 0,
        nameGap: 40,
        offset: 0,
      },
    };
  }, [fluidType, palette.customColor.black]);

  const fmbChartLineSeries = useMemo(() => {
    let series: FossilyticsChartSeries[] = [];
    let lines: FossilyticsChartLine[] = [];

    if (fmbChart) {
      caseKey.forEach((key, index) => {
        const currentItem = fmbChart[key as keyof CaseResult<FmbChartAnalysis>];
        series.push({
          name: `${dictionary.fmb.flowingMaterialBalance} ${safeFmbDictionary[key]}`,
          type: "scatter",
          defaultDisabled: key !== "mid",
          color: colorScheme[index],
          data: currentItem.normalized_cumulative_production.map((point, index) => [point, currentItem.normalized_rates[index]]),
        });
      });

      const { generatedLines, generatedSeries } = generateLineSeries({
        caseObject: fmbChart,
        colorList: colorScheme,
        prefixName: "FMB ",
      });

      lines = generatedLines;
      series = series.concat(generatedSeries);
    }

    return {
      series,
      lines,
    };
  }, [fmbChart, colorScheme]);

  return { fmbChartLineSeries, fmbChartAxes };
};

export default useFmbCharts;

import { useMemo } from "react";

import CustomCard from "@/components/Card";
import Tabs from "@/components/Tabs";
import CustomTable from "@/components/CustomTable";
import DropdownField from "@/components/fields/DropdownField";

import dictionary from "@/constants/dictionary";
import { AnalysisFmbChartOilResult } from "@/models/autoFmb";
import { capitalizeFirstLetter } from "@/utils/general";

import { useOilDataTable, useAutoFmbAnalysisChart, AnalysisChartFmbProps } from "../../hooks/analysis";
import { caseKey } from "../../constants";

import FmbChart from "./charts/FmbChart";
import PiChart from "./charts/PiChart";

const AnalysisChartOilFmb = ({ isLoading, analysisResult, onDragChartHandlebar, autoFmbState, tabIndex, fluidType }: AnalysisChartFmbProps) => {
  const { chartResult, colorScheme, symbols, units, palette } = useAutoFmbAnalysisChart({
    analysisResult,
    autoFmbState,
    fluidType,
  });

  const typedChartResult = chartResult as AnalysisFmbChartOilResult;

  const { dataTableColumn, dataTableRow, selectedCase, setSelectedCase } = useOilDataTable({
    dataTable: typedChartResult?.data_tables,
    symbols,
    units,
  });

  const stillLoading = isLoading || !typedChartResult?.fmb_chart;
  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.fmb.fmb}</span>,
        key: dictionary.fmb.fmb,
        content: (
          <FmbChart
            fluidType={fluidType}
            colorScheme={colorScheme}
            isLoading={isLoading}
            onDragChartHandlebar={onDragChartHandlebar}
            palette={palette}
            fmbChart={typedChartResult?.fmb_chart}
          />
        ),
      },
      {
        label: <span>{dictionary.fmb.pi}</span>,
        key: dictionary.fmb.pi,
        content: (
          <PiChart
            fluidType={fluidType}
            colorScheme={colorScheme}
            isLoading={isLoading}
            onDragChartHandlebar={onDragChartHandlebar}
            palette={palette}
            piChart={typedChartResult?.productivity_index_chart}
          />
        ),
      },
      {
        label: <span>{dictionary.dataTable}</span>,
        key: " Data table",
        content: (
          <div>
            <DropdownField
              label={dictionary.fmb.caseOption}
              options={caseKey.map((key) => ({
                key,
                text: capitalizeFirstLetter(key),
              }))}
              selectedKey={selectedCase}
              onChange={(v) => setSelectedCase(v)}
              disabled={isLoading}
            />
            {tabIndex === 2 && <CustomTable stickyTopRows={1} rows={dataTableRow} columns={dataTableColumn} isLoading={isLoading} />}
          </div>
        ),
      },
    ];
  }, [
    colorScheme,
    isLoading,
    onDragChartHandlebar,
    palette,
    typedChartResult?.fmb_chart,
    typedChartResult?.productivity_index_chart,
    selectedCase,
    tabIndex,
    dataTableRow,
    dataTableColumn,
    setSelectedCase,
  ]);

  return (
    <CustomCard
      style={{
        opacity: stillLoading ? 0.5 : 1,
        position: "relative",
        display: "flex",
        height: "100%",
      }}
    >
      {!typedChartResult?.fmb_chart && <div className="overlay-section" />}

      <Tabs tabList={tabList} />
    </CustomCard>
  );
};

export default AnalysisChartOilFmb;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Autocomplete,
  TextField,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AFA_WELL_TYPE } from "@/components/TreeView/constants";
import { HeaderMapping, NormalizedHeaders, formatUnit } from "@/models/wells";
import {
  DIALOG_CONSTANTS,
  MANUAL_WELL_TYPE_OPTIONS,
  getDateUnitFromFiles,
  DATE_OPTIONS,
  DATE_FORMAT_TO_PYTHON,
  DateFormat,
  PYTHON_TO_DATE_FORMAT,
} from "./helper";
import dictionary from "@/constants/dictionary";
import helpLinkUrl from "@/constants/helpLinkUrl";

type ParsedFile = {
  fileName: string;
  headers: string[];
  rows: string[][];
};

type HeaderMappingDialogProps = {
  open: boolean;
  parsedFiles: ParsedFile[];
  currentFileIndex: number;
  setCurrentFileIndex: (index: number) => void;
  manualWellType: string | null;
  setManualWellType: (val: string | null) => void;
  headerMapping: Record<string, HeaderMapping>;
  onMappingChange: (newMapping: Record<string, HeaderMapping>) => void;
  onConfirm: () => void;
  onCancel: () => void;
  normalizedFields: NormalizedHeaders | null;
};

const HeaderMappingDialog: React.FC<HeaderMappingDialogProps> = ({
  open,
  parsedFiles,
  currentFileIndex,
  setCurrentFileIndex,
  manualWellType,
  setManualWellType,
  headerMapping,
  onMappingChange,
  onConfirm,
  onCancel,
  normalizedFields,
}) => {
  if (parsedFiles.length === 0) return null;

  const { fileName, rows } = parsedFiles[currentFileIndex];
  const headers = parsedFiles[0].headers; // all files have the same headers

  // If any CSV column is mapped to "AFA-welltype," the user is doing column-based well type
  // => user should NOT pick a manual well type
  const isAutoWellTypeSelected = Object.values(headerMapping).some((mapping) => mapping.normalizedField === AFA_WELL_TYPE);

  const templateFieldOptions = normalizedFields ? Object.keys(normalizedFields) : [];

  // Field selection
  const handleNormalizedFieldChange = (header: string) => (event: SelectChangeEvent<string>) => {
    const newFieldVal = event.target.value === DIALOG_CONSTANTS.NOT_USED ? null : event.target.value;

    const newMapping = { ...headerMapping };
    const currentMapping = newMapping[header] ?? {
      normalizedField: null,
      normalizedUnit: null,
    };

    let newUnit: string | null = null;
    if (newFieldVal === DIALOG_CONSTANTS.DATE_FIELD) {
      const guessedFormat = getDateUnitFromFiles(parsedFiles, header);
      newUnit = guessedFormat && DATE_FORMAT_TO_PYTHON[guessedFormat as DateFormat] ? DATE_FORMAT_TO_PYTHON[guessedFormat as DateFormat] : null;
    }

    newMapping[header] = {
      ...currentMapping,
      normalizedField: newFieldVal,
      normalizedUnit: newUnit,
    };

    // If user picks AFA-welltype => reset manualWellType to null
    if (newFieldVal === AFA_WELL_TYPE) setManualWellType(null);

    onMappingChange(newMapping);
  };

  // Unit selection
  const handleNormalizedUnitChange = (header: string) => (event: SelectChangeEvent<string>) => {
    const rawVal = event.target.value;
    const chosenUnit = [DIALOG_CONSTANTS.CHOOSE_UNIT, DIALOG_CONSTANTS.CHOOSE_WELL_TYPE, DIALOG_CONSTANTS.UNRECOGNIZED_DATE].includes(rawVal)
      ? null
      : rawVal;

    const newMapping = { ...headerMapping };
    const currentMapping = newMapping[header] || {
      normalizedField: null,
      normalizedUnit: null,
    };

    let finalUnit = chosenUnit;
    if (currentMapping.normalizedField === DIALOG_CONSTANTS.DATE_FIELD && chosenUnit) {
      finalUnit = DATE_FORMAT_TO_PYTHON[chosenUnit as DateFormat] ?? null;
    }

    newMapping[header] = {
      ...currentMapping,
      normalizedUnit: finalUnit,
    };

    onMappingChange(newMapping);
  };

  const handleDialogClose = (_event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    onCancel();
  };

  // For previewing multiple files
  const handleFileSelectChange = (_event: any, newValue: string | null) => {
    const idx = parsedFiles.findIndex((file) => file.fileName === newValue);
    if (idx !== -1) setCurrentFileIndex(idx);
  };

  // If user picks a manual well type => remove any existing "AFA-welltype" columns
  const handleManualWellTypeSelect = (event: SelectChangeEvent<string>) => {
    const chosen = event.target.value === DIALOG_CONSTANTS.CHOOSE_WELL_TYPE ? null : event.target.value;

    if (chosen) {
      const newMapping = { ...headerMapping };
      Object.keys(newMapping).forEach((col) => {
        if (newMapping[col].normalizedField === AFA_WELL_TYPE) {
          newMapping[col] = {
            normalizedField: null,
            normalizedUnit: null,
          };
        }
      });
      onMappingChange(newMapping);
    }
    setManualWellType(chosen);
  };

  const StyledLink = styled("a")(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  }));

  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth="lg" fullWidth data-testid="header-mapping-dialog">
      <DialogTitle style={{ fontWeight: "bolder" }} data-testid="dialog-title">
        <StyledLink target="_blank" rel="noreferrer" href={helpLinkUrl.wellImport}>
          {dictionary.nav.importWells} <InfoOutlinedIcon fontSize="small" />
        </StyledLink>
      </DialogTitle>

      <DialogContent sx={{ padding: 3 }}>
        {/* If there's NO column mapped to "AFA-welltype," we let the user pick a manual well type */}
        {!isAutoWellTypeSelected && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1em",
              marginBottom: "1em",
            }}
          >
            <h4 style={{ margin: "0 10px 0 0" }}>Select Well Type:</h4>
            <FormControl size="small" style={{ width: "200px" }}>
              <InputLabel>AFA Well Type</InputLabel>
              <Select
                label="AFA Well Type"
                data-testid="manual-well-type-select"
                value={manualWellType ?? DIALOG_CONSTANTS.CHOOSE_WELL_TYPE}
                onChange={handleManualWellTypeSelect}
                renderValue={(selected) => (
                  <span
                    style={{
                      color: selected === DIALOG_CONSTANTS.CHOOSE_WELL_TYPE ? "red" : "inherit",
                    }}
                  >
                    {selected}
                  </span>
                )}
              >
                <MenuItem value={DIALOG_CONSTANTS.CHOOSE_WELL_TYPE} style={{ color: "red" }}>
                  <em>{DIALOG_CONSTANTS.CHOOSE_WELL_TYPE}</em>
                </MenuItem>
                {MANUAL_WELL_TYPE_OPTIONS.map((item) => (
                  <MenuItem key={item} value={item} data-testid={`manual-well-type-option-${item}`}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}

        {/* The table for CSV columns => Field + Unit */}
        <div style={{ marginTop: "10px" }} data-testid="header-matching-section">
          <TableContainer style={{ maxHeight: 400 }} data-testid="header-matching-table-container">
            <Table size="small" stickyHeader data-testid="header-matching-table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", backgroundColor: "#f5f5f5", width: "30%" }}>Column in File</TableCell>
                  <TableCell style={{ fontWeight: "bold", backgroundColor: "#f5f5f5", width: "35%" }}>Field</TableCell>
                  <TableCell style={{ fontWeight: "bold", backgroundColor: "#f5f5f5", width: "35%" }}>Unit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {headers.map((header) => {
                  const currentMapping = headerMapping[header] || {
                    normalizedField: null,
                    normalizedUnit: null,
                  };
                  const selectedField = currentMapping.normalizedField;

                  const isDateField = selectedField === DIALOG_CONSTANTS.DATE_FIELD;
                  const isAfaWellType = selectedField === AFA_WELL_TYPE;

                  // Gather possible units
                  let availableUnits: string[] = [];
                  if (isDateField) {
                    availableUnits = [...DATE_OPTIONS];
                  } else if (selectedField && normalizedFields) {
                    availableUnits = normalizedFields[selectedField as keyof NormalizedHeaders] || [];
                  }

                  // disable Unit if "Not Used," "Tag," or "AFA-welltype"
                  const isUnitDisabled = !selectedField || selectedField === DIALOG_CONSTANTS.TAG_FIELD || isAfaWellType;

                  // Prevent double-mapping the same Field across multiple headers
                  const selectedNormalizedFields = Object.entries(headerMapping)
                    .filter(([col, val]) => col !== header && val.normalizedField && val.normalizedField !== DIALOG_CONSTANTS.TAG_FIELD)
                    .map(([, val]) => val.normalizedField);

                  const availableNormalizedFields = templateFieldOptions.filter(
                    (field) => !selectedNormalizedFields.includes(field) || field === DIALOG_CONSTANTS.TAG_FIELD || field === selectedField
                  );

                  // fallback for unit
                  const fallbackMenuValue = isDateField ? DIALOG_CONSTANTS.UNRECOGNIZED_DATE : DIALOG_CONSTANTS.CHOOSE_UNIT;

                  return (
                    <TableRow key={header} data-testid={`header-row-${header}`}>
                      <TableCell style={{ width: "30%" }} data-testid={`header-cell-${header}`}>
                        {header}
                      </TableCell>

                      {/* Field Column */}
                      <TableCell style={{ width: "35%" }}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <Select
                            value={selectedField ?? DIALOG_CONSTANTS.NOT_USED}
                            onChange={handleNormalizedFieldChange(header)}
                            renderValue={(sel) => (
                              <span
                                style={{
                                  color: sel === DIALOG_CONSTANTS.NOT_USED ? "red" : "inherit",
                                }}
                              >
                                {sel}
                              </span>
                            )}
                            style={{ fontSize: "0.9rem" }}
                            MenuProps={{
                              PaperProps: {
                                style: { maxHeight: 300 },
                              },
                            }}
                            data-testid={`template-field-select-${header}`}
                          >
                            <MenuItem value={DIALOG_CONSTANTS.NOT_USED} style={{ color: "red" }}>
                              <em>{DIALOG_CONSTANTS.NOT_USED}</em>
                            </MenuItem>
                            {availableNormalizedFields.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>

                      {/* Unit Column */}
                      <TableCell style={{ width: "35%" }}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <Select
                            value={currentMapping.normalizedUnit ?? fallbackMenuValue}
                            onChange={handleNormalizedUnitChange(header)}
                            renderValue={(sel) => {
                              const isRed = sel === DIALOG_CONSTANTS.CHOOSE_UNIT || sel === DIALOG_CONSTANTS.UNRECOGNIZED_DATE;
                              const friendlyDateFormate = PYTHON_TO_DATE_FORMAT[sel as string];
                              const displayVal = friendlyDateFormate || sel;
                              return <span style={{ color: isRed ? "red" : "inherit" }}>{displayVal}</span>;
                            }}
                            disabled={isUnitDisabled}
                            style={{ fontSize: "0.9rem" }}
                            data-testid={`template-unit-select-${header}`}
                          >
                            <MenuItem value={fallbackMenuValue} style={{ color: "red" }}>
                              <em>{fallbackMenuValue}</em>
                            </MenuItem>
                            {availableUnits.map((unit) => (
                              <MenuItem key={unit} value={unit}>
                                {formatUnit(unit)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {/* Preview Section */}
        <div style={{ marginTop: "20px" }} data-testid="preview-section">
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 style={{ marginRight: "10px" }}>Preview File:</h4>
            <Autocomplete
              options={parsedFiles.map((f) => f.fileName)}
              value={fileName}
              onChange={handleFileSelectChange}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Select File" variant="outlined" size="small" data-testid="select-file-input" />}
            />
          </div>
          <TableContainer style={{ maxHeight: 200, overflowX: "auto" }} data-testid="preview-table-container">
            <Table size="small" stickyHeader data-testid="preview-table" style={{ minWidth: 800 }}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell key={header} style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }} data-testid={`preview-header-${header}`}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody data-testid="preview-table-body">
                {rows.map((row) => {
                  const rowKey = row.join("||");
                  return (
                    <TableRow key={rowKey} data-testid={`preview-row-${rowKey}`}>
                      {row.map((cell, idx) => (
                        <TableCell key={headers[idx]} data-testid={`preview-cell-${headers[idx]}-${cell}`}>
                          {cell}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>

      <DialogActions style={{ marginTop: "5px" }} data-testid="dialog-actions">
        <Button onClick={onCancel} data-testid="cancel-button">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" style={{ color: "white" }} data-testid="confirm-button">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HeaderMappingDialog;

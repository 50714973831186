import FossilyticsChart from "@/components/FossilyticsChart";

import { usePzChartsProps, usePzChart } from "../../../hooks/analysis";
import { BasePropsChart } from "../../../hooks/useAutoFmbAnalysis";

type PzChartProps = usePzChartsProps & BasePropsChart;

const PzChart = ({ isLoading, onDragChartHandlebar, ...rest }: PzChartProps) => {
  const { pzAxes, pzLineSeries } = usePzChart(rest);

  return (
    <div
      style={{
        height: 600,
      }}
    >
      <FossilyticsChart
        id={"pz_chart"}
        key={"pz_chart"}
        isLoading={isLoading}
        xAxes={[pzAxes.xAxis]}
        yAxes={[pzAxes.yAxis]}
        series={pzLineSeries.series}
        lines={pzLineSeries.lines}
        onLineChange={onDragChartHandlebar}
      />
    </div>
  );
};

export default PzChart;

import { useMemo, useState } from "react";
import { Row } from "@silevis/reactgrid";
import { AnalysisFmbOilDataTable, CaseResult } from "@/models/autoFmb";

import { caseKey } from "../../../constants";
import { generateDataTableColumn, generateDataTableRow } from "../../utils";

type useOilDataTableProps = {
  dataTable?: CaseResult<AnalysisFmbOilDataTable>;
  symbols?: Record<string, string>;
  units?: Record<string, string>;
};

const useOilDataTable = ({ dataTable, symbols, units }: useOilDataTableProps) => {
  const [selectedCase, setSelectedCase] = useState<string>(caseKey[0]);

  // data table
  const dataTableColumn = useMemo(() => {
    return generateDataTableColumn(selectedCase, dataTable);
  }, [dataTable, selectedCase]);

  const dataTableRow: Row<any>[] = useMemo(() => {
    return generateDataTableRow({
      column: dataTableColumn,
      selectedCase,
      symbols,
      units,
      dataTable,
    });
  }, [dataTable, dataTableColumn, selectedCase, symbols, units]);

  return {
    dataTableColumn,
    dataTableRow,
    setSelectedCase,
    selectedCase,
  };
};

export default useOilDataTable;

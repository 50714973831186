import { postExportFmbApi } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { ModuleIdentity, Interval, FluidType } from "@/models/Generic";

type ExportPayload = {
  projectId: string;
  body: ModuleIdentity;
  interval: Interval;
  fluid: FluidType;
};

export const postExportAutoFmbForecast = ({ body, interval, projectId, fluid = FluidType.gas }: ExportPayload) => {
  return fetchApi<Blob>({
    path: postExportFmbApi(projectId),
    body,
    type: "post",
    responseType: "blob",
    queryStringParameters: {
      interval,
      fluid,
    },
  });
};

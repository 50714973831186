import { useMemo, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import CustomCard from "@/components/Card";
import Tabs from "@/components/Tabs";
import CustomTable from "@/components/CustomTable";
import DropdownField from "@/components/fields/DropdownField";

import dictionary from "@/constants/dictionary";
import { AnalysisFmbChartGasResult } from "@/models/autoFmb";
import { capitalizeFirstLetter } from "@/utils/general";

import { useGasDataTable, useAutoFmbAnalysisChart, pointManualDataTableColumn, AnalysisChartFmbProps } from "../../hooks/analysis";
import { caseKey } from "../../constants";

import FmbChart from "./charts/FmbChart";
import PzChart from "./charts/PZChart";
import PiChart from "./charts/PiChart";

const AnalysisChartGasFmb = ({ isLoading, analysisResult, onDragChartHandlebar, autoFmbState, tabIndex, fluidType }: AnalysisChartFmbProps) => {
  const [isExpand, setIsExpand] = useState(true);

  const { chartResult, colorScheme, symbols, units, palette } = useAutoFmbAnalysisChart({
    analysisResult,
    autoFmbState,
    fluidType,
  });

  const typedChartResult = chartResult as AnalysisFmbChartGasResult;

  const { dataTableColumn, dataTableRow, pzPointDataTableRow, selectedCase, setSelectedCase } = useGasDataTable({
    dataTable: typedChartResult?.data_tables,
    symbols,
    units,
  });

  const stillLoading = isLoading || !typedChartResult?.fmb_chart;
  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.fmb.fmb}</span>,
        key: dictionary.fmb.fmb,
        content: (
          <FmbChart
            fluidType={fluidType}
            colorScheme={colorScheme}
            isLoading={isLoading}
            onDragChartHandlebar={onDragChartHandlebar}
            palette={palette}
            fmbChart={typedChartResult?.fmb_chart}
          />
        ),
      },
      {
        label: <span>{dictionary.fmb.pz}</span>,
        key: dictionary.fmb.pz,
        content: (
          <PzChart
            colorScheme={colorScheme}
            isLoading={isLoading}
            onDragChartHandlebar={onDragChartHandlebar}
            palette={palette}
            pzChart={typedChartResult?.p_on_z_chart}
          />
        ),
      },
      {
        label: <span>{dictionary.fmb.pi}</span>,
        key: dictionary.fmb.pi,
        content: (
          <PiChart
            fluidType={fluidType}
            colorScheme={colorScheme}
            isLoading={isLoading}
            onDragChartHandlebar={onDragChartHandlebar}
            palette={palette}
            piChart={typedChartResult?.productivity_index_chart}
          />
        ),
      },
      {
        label: <span>{dictionary.dataTable}</span>,
        key: " Data table",
        content: (
          <div>
            {typedChartResult?.data_tables?.p_on_z_manual_table && (
              <div>
                <h4
                  onKeyDown={() => setIsExpand(!isExpand)}
                  onClick={() => setIsExpand(!isExpand)}
                  style={{ margin: 0, display: "flex", justifyContent: "space-between", marginTop: 20, cursor: "pointer" }}
                >
                  {dictionary.fmb.pzManualPoint} Data {isExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </h4>

                {isExpand && (
                  <CustomTable
                    columns={pointManualDataTableColumn}
                    rows={pzPointDataTableRow}
                    enableRangeSelection
                    enableColumnSelection
                    stickyTopRows={2}
                    isLoading={false}
                    style={{
                      maxHeight: 300,
                    }}
                  />
                )}
              </div>
            )}

            <DropdownField
              label={dictionary.fmb.caseOption}
              options={caseKey.map((key) => ({
                key,
                text: capitalizeFirstLetter(key),
              }))}
              selectedKey={selectedCase}
              onChange={(v) => setSelectedCase(v)}
              disabled={isLoading}
            />
            {tabIndex === 2 && <CustomTable stickyTopRows={1} rows={dataTableRow} columns={dataTableColumn} isLoading={isLoading} />}
          </div>
        ),
      },
    ];
  }, [
    colorScheme,
    isLoading,
    onDragChartHandlebar,
    palette,
    typedChartResult?.fmb_chart,
    typedChartResult?.p_on_z_chart,
    typedChartResult?.productivity_index_chart,
    typedChartResult?.data_tables?.p_on_z_manual_table,
    isExpand,
    pzPointDataTableRow,
    selectedCase,
    tabIndex,
    dataTableRow,
    dataTableColumn,
    setSelectedCase,
  ]);

  return (
    <CustomCard
      style={{
        opacity: stillLoading ? 0.5 : 1,
        position: "relative",
        display: "flex",
        height: "100%",
      }}
    >
      {!typedChartResult?.fmb_chart && <div className="overlay-section" />}

      <Tabs tabList={tabList} />
    </CustomCard>
  );
};

export default AnalysisChartGasFmb;

import { FluidType } from "@/models/Generic";
import dictionary from "@/constants/dictionary";

export const defaultAutoFmbState = {
  tabIndex: 0,
  dataSets: [],
  isLoading: false,
  fluidModuleType: FluidType.gas,
  setTabIndex: () => {},
  setApiError: () => {},
  setIsLoading: () => {},
  setAutoFmbState: () => {},
  setProgress: () => {},
  setPollStatus: () => {},
  setAutoFmbForecastCalculation: () => {},
  setAutoFmbAnalysisCalculation: () => {},

  updateInputFromAnalysis: false,
  setUpdateInputFromAnalysis: () => {},
  expandParameter: true,
};

export const summaryFmbTableHeader = [
  {
    key: "parameter",
    label: dictionary.fmb.parameter,
  },
  {
    key: "low",
    label: dictionary.fmb.low,
  },
  {
    key: "mid",
    label: dictionary.fmb.mid,
  },
  {
    key: "high",
    label: dictionary.fmb.high,
  },
  {
    key: "units",
    label: dictionary.fmb.units,
  },
];

export const caseKey = ["low", "mid", "high"];

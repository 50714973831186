import { useState, useCallback, useMemo } from "react";
import { CellChange, DateCell, DropdownCell, Row } from "@silevis/reactgrid";
import _ from "lodash";

import { autoRtaForecast } from "@/constants/apiUrl";
import { autoRtaForecastEventHeader, autoRtaForecastEventNotation } from "../../constants";

import { AutoRtaForecastRes, autoRtaForecastResScheme, AutoRtaForecastV2 } from "@/models/gaz/autoRta";
import { AutoRtaForecastPayload } from "@/models/gaz/autoRta/State";

// utils
import useThemeStyling from "@/utils/useThemeStyling";
import { usePolling } from "@/utils/apiFetcher";

import { useAutoRtaState } from "../AutoRtaContext";

import { convertDateToUtcTimeZoneIsoString } from "@/utils/dateTime";
import { FluidType } from "@/models/Generic";

export type AutoRtaForecastProps = {
  currentTab: number;
  isLoading: boolean;
};

const dropdownOption = [
  {
    value: "Rate",
    label: "Rate",
  },
  {
    value: "Pressure",
    label: "Pressure",
  },
];

export const generateForecastEventRow = ({
  forecast,
  fluidType,
  isDropdownOpened,
  isLoading,
  palette,
}: {
  forecast?: AutoRtaForecastV2;
  fluidType: FluidType;
  isLoading: boolean;
  isDropdownOpened: number;
  palette: any;
}): Row[] => {
  if (!forecast?.forecast_events) return [];

  const style = {
    backgroundColor: forecast.smart_fill ? "rgba(128, 128, 128, 0.1)" : "white",
    cursor: forecast.smart_fill ? "not-allowed" : "auto",
  };

  // combine date array from backend with the frontend empty array
  const combinedDateArray = [...forecast.forecast_events.dates, ...Array(100).fill(undefined)];
  const constantInputsRows = [
    autoRtaForecastEventHeader,
    autoRtaForecastEventNotation(fluidType),
    ...(combinedDateArray.map((date, i) => {
      const isRate = forecast.forecast_events.bound_condition_is_rate?.[i];

      let rateSelectedVal: any = isRate ? "Rate" : "Pressure";
      if (String(isRate) === "undefined") rateSelectedVal = undefined;
      return {
        rowId: i + 1,
        cells: [
          {
            type: "date",
            format: Intl.DateTimeFormat(),
            date: date ? new Date(date) : undefined,
            hideZero: true,
            nonEditable: forecast.smart_fill || isLoading,
            style,
          },
          {
            type: "number",
            value: forecast.forecast_events.flowing_rate?.[i] ?? NaN,
            nonEditable: forecast.smart_fill || isLoading,
            style,
          },
          {
            type: "number",
            value: forecast.forecast_events.flowing_pressure?.[i] ?? NaN,
            nonEditable: forecast.smart_fill || isLoading,
            style,
          },
          {
            type: "dropdown",
            selectedValue: rateSelectedVal,
            values: dropdownOption,
            isOpen: isDropdownOpened === i + 1,
            nonEditable: forecast.smart_fill || isLoading,

            style: {
              ...style,
              color: isRate ? palette.success.main : palette.warning.main,
            },
          },
        ],
      };
    }) ?? []),
  ] as Row[];

  return constantInputsRows;
};

const useAutoRtaForecast = () => {
  const {
    autoRtaState,
    setAutoRtaState,
    apiError,
    dataSet,
    project,
    isLoading,
    setApiError,
    setValidationError,
    setIsLoading,
    setPollStatus,
    setProgress,
    fluidType,
    validationError,
    autoRtaForecastCalculation,
    setAutoRtaForecastCalculation,
    expandParameter,
    setHaveChangeValue,
  } = useAutoRtaState();
  const [isDropdownOpened, setIsDropdownOpened] = useState<number>(0);

  const { palette } = useThemeStyling();

  const { createPoll, canCancelPoll, onCancelPoll } = usePolling({
    setApiError,
    setErrorValidation: setValidationError,
    setLoadingState: setIsLoading,
    setProgressStatus: (val) => {
      setProgress(val.progress ?? null);
      setPollStatus(val.pollStatus);
    },
    apiError,
  });

  // forecast input and setting part
  const forecastEventsRow = useMemo(() => {
    return generateForecastEventRow({
      fluidType,
      forecast: autoRtaState?.forecast,
      isDropdownOpened,
      isLoading,
      palette,
    });
  }, [fluidType, autoRtaState?.forecast, isDropdownOpened, isLoading, palette]);

  const onChangeForecastInput = useCallback(
    (updatedInput: { [key: string]: any }) => {
      setValidationError([]);
      setAutoRtaState((prev) => {
        if (!prev) return prev;
        const updatedForecast = {
          ...prev.forecast,
          ...updatedInput,
        };

        return {
          ...prev,
          forecast: updatedForecast,
        };
      });
      // Reset autoRtaForecastCalculation
      setHaveChangeValue(true);
      setAutoRtaForecastCalculation(undefined);
    },
    [setAutoRtaForecastCalculation, setAutoRtaState, setHaveChangeValue, setValidationError]
  );

  const onChangeForecastInputTable = useCallback(
    (changes: CellChange[]) => {
      setValidationError([]);
      let haveChange = false;
      // Clone the original data to avoid mutation
      const newForecastFlowingEvent: any = _.cloneDeep(autoRtaState?.forecast.forecast_events);

      // Apply the changes to the cloned data
      for (const element of changes) {
        let { rowId, columnId, newCell, type, previousCell } = element as CellChange<any>;
        rowId = rowId as number;
        columnId = columnId as string;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        if (type === "dropdown") {
          const newDropdown = dropDownNewCell.isOpen ? rowId : 0;
          if (prevCell.isOpen !== dropDownNewCell.isOpen && newDropdown !== isDropdownOpened) {
            setIsDropdownOpened(newDropdown);
          } else {
            setIsDropdownOpened(0);
          }
          if (dropDownNewCell.isOpen) return;
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
            haveChange = true;
            newForecastFlowingEvent.bound_condition_is_rate[rowId - 1] = dropDownNewCell.selectedValue === "Rate";
          }
        } else {
          let val = newCell.value ?? newCell.text ?? 0;
          if (columnId === "dates") {
            const dateCell = newCell as DateCell;
            val = convertDateToUtcTimeZoneIsoString(new Date(dateCell.date ?? 0)) ?? "";
          }
          newForecastFlowingEvent[columnId][rowId - 1] = val;
          haveChange = true;
        }
      }

      if (haveChange) {
        setAutoRtaState((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            forecast: {
              ...prev.forecast,
              forecast_events: newForecastFlowingEvent,
            },
          };
        });
        // Reset autoRtaForecastCalculation
        setAutoRtaForecastCalculation(undefined);
      }
    },
    [autoRtaState?.forecast.forecast_events, isDropdownOpened, setAutoRtaState, setValidationError, setAutoRtaForecastCalculation]
  );

  const onClickCalculateForecast = useCallback(async () => {
    if (!project?.id || !dataSet || !autoRtaState?.analysis || !autoRtaState?.inputs || !autoRtaState.forecast) return;

    try {
      const response = await createPoll<AutoRtaForecastRes, AutoRtaForecastPayload>({
        path: autoRtaForecast(project?.id, fluidType),
        body: {
          data_set_ids: dataSet.map((data) => data.id),
          analysis: autoRtaState.analysis,
          inputs: autoRtaState.inputs,
          forecast: autoRtaState.forecast,
        },
        withTaskInfo: true,
        type: "post",
      });
      if (response.task_result) {
        const parsed = autoRtaForecastResScheme.parse(response.task_result);
        setAutoRtaForecastCalculation(parsed.forecast_result);
        if (!_.isEqual(parsed.forecast_inputs, autoRtaState?.forecast) || !_.isEqual(parsed.field_data, autoRtaState?.field_data)) {
          setAutoRtaState((prev) => {
            if (!prev) return prev;
            return {
              ...prev,
              forecast: parsed.forecast_inputs,
              field_data: parsed.field_data,
            };
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [autoRtaState, createPoll, dataSet, fluidType, project?.id, setAutoRtaState, setAutoRtaForecastCalculation]);

  return {
    forecastInputState: autoRtaState?.forecast,
    onChangeForecastInput,
    onChangeForecastInputTable,
    forecastEventsRow,
    loadForecast: isLoading,
    onClickCalculateForecast,
    errorInputValidation: validationError,
    autoRtaForecastParameter: autoRtaForecastCalculation?.summary_card,
    fluidType,
    canCancelPoll,
    onCancelPoll,
    expandParameter,
    autoRtaForecastCalculation,
    autoRtaFieldData: autoRtaState?.field_data,
  };
};

export default useAutoRtaForecast;

import { useCallback, useEffect, useState } from "react";
import { DialogEnum } from "../types";
import { DataSet, Group, NewGroups, NewProject, Project } from "@/model";
import { getIdFromActiveKey } from "../utils";

import { parseErrorThrown } from "@/utils/errorHandling";
import { fetchApi } from "@/utils/apiFetcher";
import { deleteDataSetApi, groupApi, projectApi, projectAddGroupApi, projectListApi, tenantUserList, transferProjectApi } from "@/constants/apiUrl";
import { ApiError } from "@/models/APIGeneric";

type UseDialogSectionProps = {
  refreshProjects: (delayStateChange?: boolean) => Promise<Project[] | undefined>;
  activeKey: string | undefined;
  setActiveKey: (val: string) => void;
  activeDialog: DialogEnum | undefined;
  setActiveDialog: (val: DialogEnum | undefined) => void;

  // from state
  setGroup: (group?: Group) => void;
  setSelectedDataSets: (dataSets?: DataSet | DataSet[]) => void;
  setProject: (project?: Project) => void;
  setSelectedKey: (key?: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  setApiError: (error?: ApiError) => void;
  apiError?: ApiError;
};

const useDialogSection = ({
  refreshProjects,
  activeKey,
  setActiveKey,
  activeDialog,
  setActiveDialog,
  setGroup,
  setApiError,
  setIsLoading,
  setProject,
  setSelectedDataSets,
  setSelectedKey,
  apiError,
}: UseDialogSectionProps) => {
  const [copyFromSelected, setCopyFromSelected] = useState(false);
  const [newGroupsNames, setNewGroupsNames] = useState<string[]>([]);

  const [transferUserIdList, setTransferUserIdList] = useState<string[]>();

  const createNewProject = useCallback(
    async (name: string) => {
      if (!name) return;
      setActiveDialog(undefined);

      const newProject: NewProject = {
        name,
      };

      if (copyFromSelected && activeKey) {
        newProject.copy_from = activeKey;
      }

      try {
        setIsLoading(true);
        await fetchApi({
          path: projectListApi,
          type: "post",
          body: newProject,
        });
        await refreshProjects();

        setCopyFromSelected(false);

        // force page refresh
        // this is a hacky workaround to get the settings to reload
        window.location.reload();
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [setActiveDialog, copyFromSelected, activeKey, setIsLoading, refreshProjects]
  );

  const resetState = useCallback(async () => {
    // when user edit project or edit group or delete something reset the state so there's no leftover
    await refreshProjects();

    setProject(undefined);
    setGroup(undefined);
    setSelectedDataSets(undefined);
    setSelectedKey(undefined);
    setActiveKey("");
  }, [refreshProjects, setActiveKey, setGroup, setProject, setSelectedDataSets, setSelectedKey]);

  const editExistingProject = useCallback(
    async (name: string) => {
      if (!name) return;
      // Close the dialog
      setActiveDialog(undefined);
      // Get project and group ID
      if (!activeKey) return;
      const { projectId } = getIdFromActiveKey(activeKey);

      try {
        setIsLoading(true);
        await fetchApi({
          path: projectApi(projectId),
          type: "put",
          body: {
            name,
          },
        });

        resetState();
      } catch (error: any) {
        console.error(error);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [activeKey, apiError, resetState, setActiveDialog, setApiError, setIsLoading]
  );

  const deleteProject = useCallback(async () => {
    if (!activeKey) return;
    // Get project and group ID
    const { projectId } = getIdFromActiveKey(activeKey);

    try {
      setIsLoading(true);
      await fetchApi({
        path: projectApi(projectId),
        type: "del",
      });
      await refreshProjects();

      // Close the dialog
      setActiveDialog(undefined);

      // force page refresh
      // this is a hacky workaround to get the settings to reload
      window.location.reload();
    } catch (error: any) {
      console.error(error);
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
    } finally {
      setIsLoading(false);
    }
  }, [activeKey, apiError, refreshProjects, setActiveDialog, setApiError, setIsLoading]);

  const removeDataSets = useCallback(async () => {
    // Get project and group ID
    if (!activeKey) return;
    const { projectId, dataSet, groupIds } = getIdFromActiveKey(activeKey);

    try {
      setIsLoading(true);
      await fetchApi({
        path: deleteDataSetApi(projectId, groupIds[groupIds.length - 1]),
        type: "del",
        body: {
          data_set_ids: dataSet,
        },
      });
      resetState();
      // Close the dialog
      setActiveDialog(undefined);
    } catch (error: any) {
      console.error(error);
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
    } finally {
      setIsLoading(false);
    }
  }, [activeKey, apiError, resetState, setActiveDialog, setApiError, setIsLoading]);

  const createNewGroups = useCallback(
    async (latestName: string) => {
      if ((newGroupsNames.length < 1 && !latestName) || !activeKey) return;
      // Close the dialog
      setActiveDialog(undefined);

      const { projectId, groupIds } = getIdFromActiveKey(activeKey);
      const nameList = newGroupsNames;

      if (latestName) {
        nameList.push(latestName);
      }
      // New groups
      const newGroups: NewGroups = {
        names: newGroupsNames,
      };
      if (groupIds.length > 0) {
        newGroups.parent_id = groupIds[groupIds.length - 1];
      }
      try {
        setIsLoading(true);
        await fetchApi({
          path: projectAddGroupApi(projectId),
          type: "post",
          body: newGroups,
        });
        // Close the dialog
        setActiveDialog(undefined);
        setNewGroupsNames([]);
        resetState();
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [activeKey, newGroupsNames, resetState, setActiveDialog, setIsLoading]
  );

  const editExistingGroup = useCallback(
    async (name: string) => {
      if (!name || !activeKey) return;

      const { projectId, groupIds } = getIdFromActiveKey(activeKey);
      const latestGroupId = groupIds[groupIds.length - 1];
      try {
        await fetchApi({
          path: groupApi(projectId, latestGroupId),
          type: "put",
          body: {
            name,
          },
        });

        resetState();
      } catch (error: any) {
        console.error(error);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [activeKey, apiError, resetState, setApiError, setIsLoading]
  );

  const getTenantUserList = useCallback(async () => {
    try {
      const res = await fetchApi<{ user_ids: string[] }>({ path: tenantUserList, type: "get" });
      if (res.data) setTransferUserIdList(res.data.user_ids);
    } catch (error) {
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
    }
  }, [apiError, setApiError]);

  const transferProject = useCallback(
    async (targetUserId: string) => {
      if (!activeKey) return;
      try {
        const { projectId } = getIdFromActiveKey(activeKey);

        await fetchApi({
          path: transferProjectApi(projectId),
          type: "post",
          body: {
            user_id: targetUserId,
          },
        });
        // force page refresh
        window.location.reload();
      } catch (error) {
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    [activeKey, apiError, setApiError]
  );

  const finalizeTransferProject = useCallback(
    async (projectId: string, approve: boolean, notDismissDialog?: boolean) => {
      try {
        setIsLoading(true);
        await fetchApi({
          path: transferProjectApi(projectId),
          type: "put",
          body: {
            approve,
          },
        });
      } catch (error) {
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      } finally {
        if (!notDismissDialog) {
          window.location.reload();
        } else {
          await refreshProjects();
        }
        setIsLoading(false);
      }
    },
    [apiError, setApiError, setIsLoading, refreshProjects]
  );

  useEffect(() => {
    if (!transferUserIdList && activeDialog === DialogEnum.TRANSFER_PROJECT) {
      getTenantUserList();
    }
  }, [activeDialog, getTenantUserList, transferUserIdList]);

  return {
    createNewProject,
    editExistingProject,
    copyFromSelected,
    setCopyFromSelected,
    deleteProject,
    removeDataSets,
    createNewGroups,
    editExistingGroup,

    newGroupsNames,
    setNewGroupsNames,

    getTenantUserList,
    transferUserIdList,
    transferProject,
    finalizeTransferProject,
  };
};

export default useDialogSection;

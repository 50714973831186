import { nativeEnum, object, z } from "zod";
import { analysisCalculationScheme, AutoRtaAnalysis, autoRtaAnalysisScheme } from "./Analysis";
import { autoRtaFieldData, autoRtaForecastCalculationSchemeV2, autoRtaForecastSchemeV2, AutoRtaForecastV2 } from "./Forecast";
import { AutoRtaInputGrid, autoRtaInputGridScheme } from "./InputGrid";
import { inputGridCalculationScheme, PressureFmbDataEnum } from "@/models/InputGeneric";

export const autoRtaScheme = object({
  inputs: autoRtaInputGridScheme,
  analysis: autoRtaAnalysisScheme,
  forecast: autoRtaForecastSchemeV2,
  available_flowing_pressures: nativeEnum(PressureFmbDataEnum).array(),
  field_data: autoRtaFieldData,
});

export const autoRtaInitializeScheme = object({
  input_inputs: autoRtaInputGridScheme,
  input_result: inputGridCalculationScheme,
  analysis_inputs: autoRtaAnalysisScheme,
  analysis_result: analysisCalculationScheme.nullable(),
  forecast_inputs: autoRtaForecastSchemeV2,
  forecast_result: autoRtaForecastCalculationSchemeV2.nullable(),
  available_flowing_pressures: nativeEnum(PressureFmbDataEnum).array(),
  field_data: autoRtaFieldData,
});

export type AutoRtaInitializeResponse = z.infer<typeof autoRtaInitializeScheme>;

export type AutoRtaState = z.infer<typeof autoRtaScheme>;

export type AnalysisPayload = {
  data_set_ids: string[];
  analysis: AutoRtaAnalysis;
  inputs: AutoRtaInputGrid;
};

export type AutoRtaForecastPayload = {
  data_set_ids: string[];
  inputs: AutoRtaInputGrid;
  analysis: AutoRtaAnalysis;
  forecast: AutoRtaForecastV2;
};
